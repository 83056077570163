<template>
  <div class="body-a">

    <div class="wrapper">
      <div class="landing-page">
        <template v-if="!setting.error">
          <section class="overflow-hidden min-vh-80"
            v-bind:class="{'v-bg': setting.layout ===  'v', 'a-bg': setting.layout ===  'a', 'c-bg': setting.layout ===  'c'}"
            :style="`background-image: ${getBackgroundImage()}`" 
            v-show="!finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0">
                <img class="img-title01" :src="setting.unidade.diz_vc_logo" alt=""/>
                <p v-if="!setting.unidade.diz_vc_texto1" class="text-center mt-4"
                  v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}">
                  <strong 
                    v-bind:class="{'a-title': setting.layout ===  'v' || setting.layout ===  'a', 'c-title': setting.layout ===  'c'}">
                    A sua satisfação é muito importante para nós!
                  </strong>
                </p>

                <template v-if="(step === 'OS' && !setting.atendimento)">
                  <div v-if="setting.unidade.diz_vc_texto1" v-html="setting.unidade.diz_vc_texto1"></div>
                  <p v-else class="text-center mt-4"
                    v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}">
                    <strong class="a-strong">Para darmos continuidade à sua avaliação precisamos que informe
                      corretamente o número do atendimento.</strong>
                    <br/>
                    <i v-bind:class="{'a-italic': setting.layout ===  'v' || setting.layout ===  'a', 'c-italic': setting.layout ===  'c'}">
                      Você encontrará essa informação em seu
                      <strong class="a-strong">recibo de atendimento.</strong>
                      O formato geralmente é <strong class="a-strong">1{{ year }}.XXXXXX</strong>
                    </i>
                  </p>

                  <div class="d-flex flex-row justify-content-center text-center  mt-2">
                    <input v-model="cliente"
                          class="p-2"
                          v-bind:class="{'a-input': setting.layout ===  'v' || setting.layout ===  'a', 'c-input': setting.layout ===  'c'}"
                          placeholder="Nome completo"
                          maxlength="40"
                          required/>
                  </div>

                  <div class="d-flex flex-row justify-content-center text-center  mt-2">
                    <input v-model="phone"
                          class="p-2"
                          v-bind:class="{'a-input': setting.layout ===  'v' || setting.layout ===  'a', 'c-input': setting.layout ===  'c'}"
                          placeholder="Celular"
                          maxlength="40"
                          required/>
                  </div>

                  <div class="d-flex flex-row justify-content-center text-center mt-2">
                    <input v-model="atendimento"
                          class="p-2"
                          v-bind:class="{'a-input': setting.layout ===  'v' || setting.layout ===  'a', 'c-input': setting.layout ===  'c'}"
                          :placeholder="'1' + [[year]] + '.XXXXXX'"
                          maxlength="40"
                          required/>
                  </div>

                  <div class="mt-4">
                    <a @click="avancarAposIdentificacao"
                      v-bind:class="{'a-btn': setting.layout ===  'v' || setting.layout ===  'a', 'c-btn': setting.layout ===  'c'}"
                      class="btn m-0 ml-2">
                      Avançar
                    </a>
                  </div>

                  <div class="mt-4">
                    <a @click="avancarAposIdentificacao"
                      v-bind:class="{'a-btn': setting.layout ===  'v' || setting.layout ===  'a', 'c-btn': setting.layout ===  'c'}"
                      class="btn m-0 ml-2">
                      Avaliar Anonimamente
                    </a>
                  </div>
                </template>
                <template id="grade" v-if="(step === 'grade' || setting.atendimento)">
                  <p class="text-center mt-4"
                    v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}"
                    v-if="setting.cliente">
                    <strong>Prezado(a) {{ setting.cliente }}</strong>
                  </p>
                  <p class="text-center mt-4"
                    v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}">
                    <div v-if="setting.unidade.diz_vc_texto2" v-html="setting.unidade.diz_vc_texto2"></div>
                    <template v-else>
                      <strong class="a-strong text-center">De 0 a 10</strong>, qual é a probabilidade de <u>você
                      recomendar</u> o(a)
                      <strong class="a-strong">{{ setting.unidade.nome }}</strong> para um familiar ou amigo?
                      <br/>
                    </template>

                    <i v-bind:class="{'a-italic': setting.layout ===  'v' || setting.layout ===  'a', 'c-italic': setting.layout ===  'c'}">
                      Sendo 0 (nada provável) e 10 (muito provável)
                    </i>
                  </p>

                  <ul class="actions special buttons d-flex flex-wrap w-100 justify-content-center">
                    <li v-for="i in 11" @click="selecionarGrade(i-1)" :key="i" class="mr-2 d-flex justify-content-center">
                      <a
                        class="d-flex justify-content-center"
                        :class="{
                          'a-btn': setting.layout ===  'v' || setting.layout ===  'a',
                          'c-btn': setting.layout ===  'c',
                          'btn-skin-red': i - 1 <= 6,
                          'btn-skin-orange': i - 1 > 6 && i - 1 <= 8,
                          'btn-skin': i - 1 > 8,
                        }"
                        :style="{'backgroundColor': grade === i - 1 ? ` ${setting.unidade.cor} !important` : ''}">
                        {{ i - 1 }}
                      </a>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </section>

          <section id="qualifiers" class="overflow-hidden a-bg-default mt-0"
            v-if="Number.isInteger(grade) && !finish">
            <Qualifiers 
              :grade="grade"
              @update:message="updateMessage"
              @finish:form="concluir"/>
          </section>

          <section class="overflow-hidden min-vh-80"
                  v-bind:class="{'v-bg': setting.layout ===  'v', 'a-bg': setting.layout ===  'a', 'c-bg': setting.layout ===  'c'}"
                  :style="`background-image: ${getBackgroundImage()}`" 
                  v-show="finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0">
                <img class="img-title01 mb-5" :src="setting.unidade.diz_vc_logo" alt=""/>
                <p class="text-center mt-4 mb-5"
                v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}">
                  <strong v-bind:class="{'ab-title': setting.layout ===  'v' || setting.layout ===  'a', 'cb-title': setting.layout ===  'c'}">Agradecemos por enviar sua opinião!</strong>
                </p>
                <div v-if="this.grade > 6">
                  <p class="text-center mt-4"
                  v-if="!setting.layout ===  'c'"
                  v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a'}">
                    <a v-if="setting.unidade.site" :href="setting.unidade.site" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('web', '#fff', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.instagram" :href="setting.unidade.instagram" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('instagram', '#fff', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.facebook" :href="setting.unidade.facebook" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('facebook', '#fff', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.twitter" :href="setting.unidade.twitter" target="__blank">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('twitter', '#fff', '60px', '60px')"/>
                    </a>
                  </p>
                  <p class="text-center mt-4"
                  v-if="setting.layout ===  'c'"
                  v-bind:class="{'c-p': setting.layout ===  'c'}">
                    <a v-if="setting.unidade.site" :href="setting.unidade.site" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('web', '#000', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.instagram" :href="setting.unidade.instagram" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('instagram', '#000', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.facebook" :href="setting.unidade.facebook" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('facebook', '#000', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.twitter" :href="setting.unidade.twitter" target="__blank">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('twitter', '#000', '60px', '60px')"/>
                    </a>
                  </p>
                  <p class="text-center mt-4"
                  v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}">
                    <strong 
                      v-bind:class="{'a-title': setting.layout ===  'v' || setting.layout ===  'a', 'c-title': setting.layout ===  'c'}">
                      Acompanhe nossas redes sociais
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </template>

        <template v-else>
          <section 
            class="overflow-hidden min-vh-80" 
            v-bind:class="{'v-bg': setting.layout ===  'v', 'a-bg': setting.layout ===  'a', 'c-bg': setting.layout ===  'c'}"
            :style="`background-image: ${getBackgroundImage()}`" 
            v-show="!finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0">
                <img class="img-title01 mb-5" :src="setting.unidade.diz_vc_logo" alt=""/>
                <p class="text-center mt-4 mb-5"
                v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}">
                  <strong v-if="!setting.hasOwnProperty('warning')" v-bind:class="{'ab-title': setting.layout ===  'v' || setting.layout ===  'a', 'cb-title': setting.layout ===  'c'}">O seu formulário não está mais disponivel,</strong>
                  <strong v-if="setting.hasOwnProperty('warning')" v-bind:class="{'ab-title': setting.layout ===  'v' || setting.layout ===  'a', 'cb-title': setting.layout ===  'c'}">Este formulário não está disponível.</strong>
                  <br>
                  <strong v-if="!setting.hasOwnProperty('warning')" v-bind:class="{'ab-title': setting.layout ===  'v' || setting.layout ===  'a', 'cb-title': setting.layout ===  'c'}">mas seu comentário nos ajudará a aperfeiçoar o atendimento.</strong>
                </p>
                <div v-if="!setting.hasOwnProperty('warning')" class="d-flex d-md-block form-group">
                    <textarea
                        placeholder="Comentário"
                        v-model="message"
                        class="form-control a-text-area text-area-bg"
                        rows="5"></textarea>
                </div>

                <div v-if="!setting.hasOwnProperty('warning')" class="d-md-block text-center">
                  <button :disabled="!message"
                    v-bind:style="{'borderColor': setting.unidade.cor}"
                    v-bind:class="{'a-btn a-btn-success': setting.layout ===  'v' || setting.layout ===  'a', 'c-btn c-btn-success': setting.layout ===  'c'}"
                    class="btn"
                    @click="concluir()">
                    Concluir
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section class="overflow-hidden min-vh-80"
            v-bind:class="{'v-bg': setting.layout ===  'v', 'a-bg': setting.layout ===  'a', 'c-bg': setting.layout ===  'c'}"
            :style="`background-image: `" 
            v-show="finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0">
                <img class="img-title01 mb-5" :src="setting.unidade.diz_vc_logo" alt=""/>
                <p class="text-center mt-4 mb-5"
                v-bind:class="{'m-p': setting.layout ===  'v' || setting.layout ===  'a', 'c-p': setting.layout ===  'c'}">
                  <strong v-bind:class="{'ab-title': setting.layout ===  'v' || setting.layout ===  'a', 'cb-title': setting.layout ===  'c'}">O seu comentário foi enviado com sucesso</strong>
                  <br>
                  <strong v-bind:class="{'ab-title': setting.layout ===  'v' || setting.layout ===  'a', 'cb-title': setting.layout ===  'c'}">e será avaliado pela nossa equipe.</strong>
                </p>
              </div>
            </div>
          </section>
        </template>

      </div>
    </div>

    <MainFooter :background-color="'black'"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {Api} from "../../services/api";
import MainFooter from "./MainFooter";
import Qualifiers from "./../Qualifiers";
import moment from "moment";
import {ApiComunicante} from "@/services/apiComunicante";

const INTIAL_STATE = {
  atendimento: null,
  grade: null,
  message: null,
  year: null,
  finish: false,
  step: 'OS',
}

export default {
  name: 'a-page',
  components: {MainFooter, Qualifiers},
  props: {
    preview: { type: Boolean, required: true },
  },
  data() {

    return {
      atendimento: null,
      cliente: null,
      phone: null,
      grade: null,
      message: null,
      year: null,
      finish: false,
      step: 'OS',
      timer: '',
      tempoInicio: '',
      tempoFinal: ''
    }
  },
  async beforeMount() {
    this.tempoInicio = moment().format('YYYY-MM-DD HH:mm:ss')
    const currenYear = new Date().getFullYear();
    this.year = currenYear.toString().slice(-2)

  },
  mounted() {
    const nota = parseInt(this.$route.params.nota);
    if(nota) {
      this.selecionarGrade(nota)
    }
  },
  computed: {
    ...mapState({
      setting: state => state.app.configs,
    })
  },
  methods: {
    selecionarGrade(grade) {
      if (grade < 0 || grade > 10) return;

      this.tempoFinal = moment().format('YYYY-MM-DD HH:mm:ss')
      ApiComunicante.enviarEstatistica({
          contaId: this.setting.conta_id,
          dataInicio: this.tempoInicio,
          dataFim: this.tempoFinal,
          uid: this.setting.uid,
          recursoTipoId: this.$route.name == 'Qr Code' ? 20 : 12,
          titulo: this.$route.name == 'Qr Code' ? 'Qr Code' : 'Pesquisa',
          descricao: 'Layout ' + this.setting.layout.toUpperCase(),
          origemChamada: ''
        }
      )
      

      this.grade = grade
      const data = this.mountData()

      data.opine = this.$route.params.opine && this.$route.params.opine === 'opine';

      if (!data.opine) {
        if (this.$route.params.id && this.$route.params.hash) {
          Api.update(this.$route.params.id, this.$route.params.hash, data)
        } else if (this.$route.params.id && this.$route.params.setor) {
          Api.updateQrCode(this.$route.params.id, this.$route.params.setor, data)
        }
      }

      this.goToDiv('qualifiers')
    },
    avancarAposIdentificacao() {
      if(this.$route.name == 'Qr Code') {
        this.tempoFinal = moment().format('YYYY-MM-DD HH:mm:ss')
        ApiComunicante.enviarEstatistica({
            contaId: this.setting.conta_id,
            dataInicio: this.tempoInicio,
            dataFim: this.tempoFinal,
            recursoTipoId: 19,
            uid: this.setting.uid,
            titulo: 'Qr Code',
            descricao: 'Layout ' + this.setting.layout.toUpperCase(),
            origemChamada: ''
          }
        )
        this.tempoInicio = moment().format('YYYY-MM-DD HH:mm:ss')
      }
      this.step = 'grade'
    },
    async concluir() {

      if(this.preview === false){
        const data = this.mountData()

        data.opine = this.$route.params.opine && this.$route.params.opine === 'opine';

        this.finish = true

        if (this.$route.params.id && this.$route.params.hash) {
          await Api.update(this.$route.params.id, this.$route.params.hash, data)
        } else if (this.$route.params.id && this.$route.params.setor) {
          await Api.updateQrCode(this.$route.params.id, this.$route.params.setor, data)
          this.timer = setTimeout("window.location.reload()", 30000);
        }

      } else {
        this.finish = true
      }

      window.scrollTo(0, 0)
    },
    mountData() {
      return {
        grade: this.grade,
        cliente: this.cliente,
        atendimento: this.atendimento ? this.atendimento : this.setting.atendimento,
        phone: this.phone,
        message: this.message,
        qualifiers: this.setting.qualifiers,
        uuid: this.setting.uuid,
      }
    },
    goToDiv(div) {
      
      setTimeout(() => {
        var elmnt = document.getElementById(div);
        elmnt.scrollIntoView();

      }, 100)
    },
    updateMessage(message) {
      this.message = message
    },
    getBackgroundImage() {
        if(this.setting.unidade.diz_vc_bg) return `url(${this.setting.unidade.diz_vc_bg})`
        return `url(/img/a/overlay2.png), url(/img/a/overlay3.svg), linear-gradient(45deg, ${this.setting.unidade.diz_vc_cor1} 5%, ${this.setting.unidade.diz_vc_cor2} 30%, ${this.setting.unidade.diz_vc_cor3}) !important;`
    }
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },

};
</script>

<style scoped>
/*TODO baixar font e add no projeto*/
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,400italic");

@media screen and (max-width: 576px) {
  .a-btn {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: none !important;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1em;
    font-weight: 800;
    font-family: "Source Sans Pro";
    /*height: 2.75em;*/
    letter-spacing: 0.125em;
    line-height: 2.75em;
    padding: 0 2em;
    text-align: center;
    text-decoration: none;
    text-indent: 0.125em;
    text-transform: uppercase;
    white-space: nowrap;

    height: 2.75em;
    border-bottom-color: rgba(255, 255, 255, 0.5);
  }
}


.body-a {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  color: #666;
  background-color: #fff;
  font-size: 13px;
  overflow-x: hidden;
  font-weight: 300;
  line-height: 1.65em;
}

.body-a > h1, h2, h3, p {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  color: #555;
}


.a-italic {
  color: rgba(255, 255, 255, 0.75);
}

.c-italic {
  color: rgba(0, 0, 0, 1);
}

.a-text-area {
  border: solid 1px rgba(144, 144, 144, 0.8) !important;
  background: rgba(144, 144, 144, 0.075);
  border-radius: 5px;
  max-height: 150px;
  height: 150px;
}

.text-area-bg{
  background-color: white;
  font-size: 1em;
}

.a-text-area:focus {
  border: solid 1px #6bd4c8 !important;
}

.halfcircle {
  width: 290px;
  height: 100px;
  z-index: -1;
  background-color: #8a8484;
  border-bottom-left-radius: 110px;
  border-bottom-right-radius: 110px;
  border: 8px solid #8f8f8f;
  border-top: 0;
  margin-right: auto;
  margin-left: auto;
}

.shadowed-grade{
  margin-right: auto;
  margin-left: auto;
  width: 200px;
  height: 75px;
  border-radius: 27px;
  border: solid 1px #DDD;
  box-shadow: 0 0 5px #7b7b7b87;
  background: #90909013;
}

@media screen and (min-width: 601px) {
            
}

.a-input {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #ffffff !important;
  color: #4686a0;
  border: none;
  border-radius: 5px;
  padding: 0;
  font-size: 1rem;
  text-transform: none;
  font-weight: normal;
  text-align: center;
  width: 40%;
  letter-spacing: 2px;
}

.c-input {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #333 !important;
  color: #ccc;
  border: #333;
  border-radius: 5px;
  padding: 0;
  font-size: 1rem;
  text-transform: none;
  font-weight: normal;
  text-align: center;
  width: 40%;
  letter-spacing: 2px;
}

.a-input::placeholder { /* Edge */
  color: #afafaf;
}

.c-input::placeholder { /* Edge */
  color: #555;
}

a.a-btn:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.125);
}

a.c-btn:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.125);
}

.a-bg-default {
  font-size: 1.3em;
  line-height: 1.35em;
  color: #555;
}

.btn:disabled:hover {
  opacity: 0.65;
}

.a-btn {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #fff;
  box-shadow: none !important;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 800;
  font-family: "Source Sans Pro";
  /*height: 2.75em;*/
  letter-spacing: 0.125em;
  line-height: 2.75em;
  padding: 0 2em;
  text-align: center;
  text-decoration: none;
  text-indent: 0.125em;
  text-transform: uppercase;
  white-space: nowrap;

  height: 2.75em;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.c-btn {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(51, 51, 51, 0.75);
  box-shadow: none !important;
  color: #333 !important;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 400;
  font-family: "Source Sans Pro";
  /*height: 2.75em;*/
  letter-spacing: 0.125em;
  line-height: 2.75em;
  padding: 0 2em;
  text-align: center;
  text-decoration: none;
  text-indent: 0.125em;
  text-transform: uppercase;
  white-space: nowrap;

  height: 2.75em;
  border-bottom-color: rgba(31, 31, 31, 0.75);
}

.a-btn:hover {
  background-color: rgba(255, 255, 255, 0.125);
  border-color: #fff;
}

.c-btn:hover {
  background-color: rgba(255, 255, 255, 0.125);
  border-color: #999;
}

.a-btn-success {
  color: #555 !important;
}

.c-btn-success {
  color: #555 !important;
}

.a-input:hover {
  background-color: rgba(255, 255, 255, 0.125) !important;
  color: #fff;
}

.c-input:hover {
  background-color: rgba(255, 255, 255, 0.125) !important;
  color: #fff;
}

.a-input:focus, .a-input:focus {
  outline: none;
}

.c-input:focus, .c-input:focus {
  outline: none;
}

.m-p {
  font-size: 1.3em;
  line-height: 35px;
  color: rgba(255, 255, 255, 0.75);
}

.c-p{
  font-size: 1.3em;
  line-height: 35px;
  color: rgba(0, 0, 0, 1);
}

.a-title {
  color: #fff;
  font-weight: 400;
  font-size: 1.5em;
}

.c-title {
  color: #000;
  font-weight: 400;
  font-size: 1.5em;
}

.ab-title {
  color: #fff;
  font-weight: 600;
  font-size: 1.8em;
}

.cb-title {
  color: #000;
  font-weight: 600;
  font-size: 1.8em;
}

.a-strong {
  font-weight: 500;
  font-size: 1.1em;
}


.a-bg {
  padding: 6em 0 3em 0;
  background-color: #4686a0;
  color: rgba(255, 255, 255, 0.75);
  background-attachment: fixed, fixed, fixed;
  background-position: top left, center center, center center;
  background-size: auto, cover, cover;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-image: url(/img/a/overlay2.png), url(/img/a/overlay3.svg), linear-gradient(45deg, #9dc66b 5%, #4fa49a 30%, #4361c2) !important;
}

.v-bg {
  padding: 6em 0 3em 0;
  color: rgba(255, 255, 255, 0.75);
  background-attachment: fixed, fixed, fixed;
  background-position: top left, center center, center center;
  background-size: auto, cover, cover;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: #b32c24;
  background-image: url(/img/a/overlay2.png), url(/img/a/overlay3.svg), linear-gradient(
      45deg, #ef4036 5%, #b32c24 15%, #8e251f) !important;
}

.c-bg {
  padding: 6em 0 3em 0;
  background-color: #fff;
  color: #000;
  background-attachment: scroll;
  background-position: top left, center center, center center;
  background-size: 100% 100%;
  overflow: visible;
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
}


/* Actions */

ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0;
}

ul.actions li {
  padding: 0 0 0 1em;
  vertical-align: middle;
}

ul.actions.special {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100%;
  margin-left: 0;
}

ul.actions.special li:first-child {
  padding-left: 0;
}

ul.actions.stacked {
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 0;
}

ul.actions.stacked li {
  padding: 1.3em 0 0 0;
}

ul.actions.stacked li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  width: calc(100% + 1em);
}

ul.actions.fit li {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}

ul.actions.fit li > * {
  width: 100%;
}

ul.actions.fit.stacked {
  width: 100%;
}

@media screen and (max-width: 480px) {

  .m-p {
    font-size: 1.2em;
    line-height: 25px;
  }

  .c-p{
    font-size: 1.2em;
    line-height: 25px;
  }

  .a-title {
    font-size: 1.2em;
  }

  ul.actions:not(.fixed) {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    width: 100% !important;
  }

  ul.actions:not(.fixed) li {
    -moz-flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -moz-flex-shrink: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
    padding: 1em 0 0 0;
    text-align: center;
    max-width: 14%;
  }

  ul.actions:not(.fixed) li > * {
    width: 100%;
  }

  ul.actions:not(.fixed) li:first-child {
    /*padding-top: 0;*/
  }

  ul.actions:not(.fixed) li input[type="submit"],
  ul.actions:not(.fixed) li input[type="reset"],
  ul.actions:not(.fixed) li input[type="button"],
  ul.actions:not(.fixed) li button,
  ul.actions:not(.fixed) li .button {
    width: 100%;
  }

  ul.actions:not(.fixed) li input[type="submit"].icon:before,
  ul.actions:not(.fixed) li input[type="reset"].icon:before,
  ul.actions:not(.fixed) li input[type="button"].icon:before,
  ul.actions:not(.fixed) li button.icon:before,
  ul.actions:not(.fixed) li .button.icon:before {
    margin-left: -0.5em;
  }

}

.buttons{
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: row;
  flex-wrap: wrap;
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
}

.buttons li{
  margin-bottom: 6px;
}

</style>

import { set, toggle } from '@/store/_helpers'
import * as MUT from '@/store/_mutation.types'

const state = {
  urlApi: process.env.VUE_APP_URL_API,
  configs: { role: false},
}

const mutations = {
  [MUT.SET_APP_CONFIGS]: set('configs'),
}

export default {
  state,
  mutations
}

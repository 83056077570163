import Service from './Service'

export class Api extends Service {
  static setting (client, hash, params) {
    params = this.httpParamSerializer(params)
    hash = decodeURIComponent(hash).replace(/\{\{.*?\}\}/g, '');
    return this.$axios.get(`${client}/api/captacao/${hash}/setting`)
  }

  static settingPreviewQrCode (client, type, unisetor, params) {
    params = this.httpParamSerializer(params)
    return this.$axios.get(`${client}/api/captacao/qr/${type}/${unisetor}`)
  }

  static update (client, hash, body) {
    return this.$axios.post(`${client}/api/captacao/${hash}`, body)
  }

  static settingQrCode (client, setor, params) {
    params = this.httpParamSerializer(params)
    return this.$axios.get(`${client}/api/captacao/qr/${setor}`)
  }


  static updateQrCode (client, setor, body) {
    return this.$axios.post(`${client}/api/captacao/qr/${setor}`, body)
  }
}

// https://vuex.vuejs.org/en/modules.html

const requireModule = require.context('.', true, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  // Replace ./ and .js
  const path = fileName.replace(/(\.\/|\.js)/g, '')
  const [moduleName, imported] = path.match('/') ? path.split('/') : [path, path]

  if (!modules[moduleName]) {
    modules[moduleName] = {
      namespaced: false
    }
  }

  if (path.match('/')) {
    modules[moduleName][imported] = requireModule(fileName).default
  } else {
    modules[moduleName] = Object.assign((modules[moduleName] || {}), requireModule(fileName).default)
  }
})
export default modules

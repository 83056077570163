<template>
  <div v-if="setting.unidade"
       v-bind:style="{'borderTop': `3px solid ${setting.unidade.cor}`}"
  class="l-header">
    <div
        type="white"
        :transparent="transparent"
        :color-on-scroll="colorOnScroll"
        class="l-header-container container"
    >
      <div >
          Obrigado
      </div>
      <div>
        <router-link class="navbar-brand" to="#">
          <img v-if="setting.unidade.diz_vc_logo"
               :src="setting.unidade.diz_vc_logo"
               :alt="setting.unidade.nome"
          height="40px"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
    personType: String
  },

  data() {
    return {
      URL_APP: process.env.VUE_APP_URL_APP,
      URL_API: process.env.VUE_APP_URL_API
    }
  },
  computed: {
    ...mapState({
      setting: state => state.app.configs,
    })
  },
  methods: {
    openChat() {
      this.$emit('openChat')
    },

    showModal() {
      this.$emit('showModal')
    }
  },
};
</script>

<style scoped>
.l-header{
  padding: 15px 0;
  border-bottom: solid #eee 1px;
}
.l-header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  font-size: 32px;
}
</style>

<template>
  <div class="landing-page" v-if="loagin">
  </div>


  <div class="" v-else>
    <l-page :preview="preview" v-if="setting.layout === 'l' "/>
    <m-page :preview="preview" v-if="setting.layout === 'm'"/>
    <a-page :preview="preview" v-if="setting.layout === 'a' || setting.layout === 'v' || setting.layout === 'c'"/>
  </div>

</template>
<script>
import {Api} from "@/services/api";
import {ApiComunicante} from "@/services/apiComunicante";
import {mapMutations, mapState} from 'vuex'
import LPage from "../layout/l/lPage";
import MPage from "../layout/m/MPage";
import APage from "../layout/a/APage";
import router from "../router";
import moment from "moment";

export default {
  name: 'landingPage',
  components: {LPage, MPage, APage},
  data() {
    return {
      loagin: true,
      preview: false,
      nota: -1
    }
  },
  computed: {
    ...mapState({
      setting: state => state.app.configs,
    })
  },

  methods: {
    ...mapMutations(['setAppConfigs']),
    tratarQualificadores(data) {
      data.qualifiers.forEach(i => {
        i.tipo_qualificadores.respostas = JSON.parse(i.tipo_qualificadores.respostas)
        i.tipo_qualificadores.vueSelecionados = []
      });
      return data
    },
    enviarEstatistica(data) {
      ApiComunicante.enviarEstatistica(data)
    },
    uniqid (prefix, moreEntropy) {
    if (typeof prefix === 'undefined') {
      prefix = ''
    }
    let retId
    const _formatSeed = function (seed, reqWidth) {
      seed = parseInt(seed, 10).toString(16) // to hex str
      if (reqWidth < seed.length) {
        // so long we split
        return seed.slice(seed.length - reqWidth)
      }
      if (reqWidth > seed.length) {
        // so short we pad
        return Array(1 + (reqWidth - seed.length)).join('0') + seed
      }
      return seed
    }
    const $global = (typeof window !== 'undefined' ? window : global)
    $global.$locutus = $global.$locutus || {}
    const $locutus = $global.$locutus
    $locutus.php = $locutus.php || {}
    if (!$locutus.php.uniqidSeed) {
      // init seed with big random int
      $locutus.php.uniqidSeed = Math.floor(Math.random() * 0x75bcd15)
    }
    $locutus.php.uniqidSeed++
    // start with prefix, add current milliseconds hex string
    retId = prefix
    retId += _formatSeed(parseInt(new Date().getTime() / 1000, 10), 8)
    // add seed hex string
    retId += _formatSeed($locutus.php.uniqidSeed, 5)
    if (moreEntropy) {
      // for more entropy we add a float lower to 10
      retId += (Math.random() * 10).toFixed(8).toString()
    }
    return retId
  }
  },
  mounted() {
  },
  async beforeMount() {
    //http://localhost:8080/9/CAN/qr/MCA

    let api = null
    
    let tempoInicio =  moment().format('YYYY-MM-DD HH:mm:ss')
    let tempoFinal = null;

    if (this.$route.params.id && this.$route.params.hash) {
      api = Api.setting(this.$route.params.id, this.$route.params.hash)
    } else if (this.$route.params.id && this.$route.params.setor) {
      api = Api.settingQrCode(this.$route.params.id, this.$route.params.setor)
    } else if (this.$route.params.id && this.$route.params.type && this.$route.params.unisetor && this.$route.params.layout) {
      this.preview = true
      api = Api.settingPreviewQrCode(this.$route.params.id, this.$route.params.type ,this.$route.params.unisetor)
    } else {
      return router.push({name: 'index'})
    }


    api.then(({data}) => {
      if (data){
        data = this.tratarQualificadores(data)
        this.setAppConfigs(data)
      }
      if(this.$route.params.layout){
        this.setting.layout = this.$route.params.layout
      }
      this.setting.uid = this.uniqid()

      tempoFinal = moment().format('YYYY-MM-DD HH:mm:ss')
      this.enviarEstatistica(
        {
          contaId: this.setting.conta_id,
          dataInicio: tempoInicio, 
          dataFim: tempoFinal,
          uid: this.setting.uid,
          recursoTipoId: this.$route.name == 'Qr Code' ? 18 : 11,
          titulo: this.$route.name == 'Qr Code' ? 'Qr Code' : 'Pesquisa',
          descricao: 'Layout ' + this.setting.layout.toUpperCase(),
          origemChamada: ''
        }
      )
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 404) {
          router.push({name: 'Page404'})
        }
      }
    })
    
    
    this.loagin = false

  }
};
</script>

<style>

</style>

<template>
  <div class="d-md-flex">
    <div
      class="col-md-2 col-sm-12 py-md-5 note-content text-center"
      :class="{
        'promotor-theme': grade >= 9,
        'neutro-theme': grade >= 7 && grade < 9,
        'detrator-theme': grade < 7,
      }"
    >
      <h2 class="a-title-note">Nota</h2>
      <h1 class="a-note">{{ grade }}</h1>
    </div>
    <div class="col-md-10 col-sm-12 note-content">
      <div class="row">
        <div
          v-if="setting.qualifiers.length !== 0"
          :class="`${
            setting.qualifiers.length > 6 ? 'col-md-8' : 'col-md-5'
          } col-sm-12 ${
            setting.qualifiers.length > 6 ? 'pt-md-0' : 'p-md-5'
          } mt-md-4 mb-md-5`"
          style="margin: auto"
        >
          <header class="layout-a responsive-spacing">
            <h2 class="a-title-small m-0">Avalie sua experiência:</h2>
          </header>

          <div
            v-for="(qualification, radioIdx) of setting.qualifiers"
            :key="`qualification-radio-${radioIdx}-${qualification.name}`"
          >
            <div :key="atualizaCampoQualificadores" class="container-toggle">
              <div class="row">
                <div
                  class="col-12"
                  :class="{
                    'mb-2': qualification.name.split(' ').length > 1,
                  }"
                >
                  <span>{{ qualification.name }}</span>
                </div>
                <div class="col-12">
                  <div v-if="qualification.tipo_qualificadores.nome === 'LIST'">
                    <div
                      v-if="
                        qualification.tipo_qualificadores.respostas.opcoes
                          .length <= 3
                      "
                      class="d-flex"
                    >
                      <div
                        class="lista"
                        :class="{
                          primeiro: index == 0,
                          ultimo:
                            qualification.tipo_qualificadores.respostas.opcoes
                              .length -
                              1 ==
                            index,
                          selecionado:
                            qualification.tipo_qualificadores.respostas
                              .vueSelecionados &&
                            qualification.tipo_qualificadores.respostas.vueSelecionados.some(
                              (i) => i.name == opcao.name
                            ),
                        }"
                        :key="index"
                        v-for="(opcao, index) in qualification
                          .tipo_qualificadores.respostas.opcoes"
                        @click="cliqueQualificadorLista(qualification, opcao)"
                      >
                        <div :name="[[qualification.name]]">
                          <label :for="[[radioIdx]] + 'Like'" class="">
                            {{ opcao.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <MultiSelect
                        appendTo="body"
                        :selectionLimit="
                          qualification.tipo_qualificadores.respostas.multi
                            ? null
                            : 1
                        "
                        @change="mudancaoDropdown(qualification)"
                        v-model="
                          qualification.tipo_qualificadores.respostas
                            .vueSelecionados
                        "
                        :options="
                          qualification.tipo_qualificadores.respostas.opcoes
                        "
                        optionLabel="name"
                        placeholder="Selecione uma opção"
                      />
                    </div>
                  </div>

                  <div
                    v-else-if="
                      qualification.tipo_qualificadores.nome === 'CSAT'
                    "
                    class="d-flex"
                    :key="atualizaCampoQualificadores"
                  >
                    <div
                      @click="cliqueQualificadorCSAT(qualification, 1)"
                      class="lista primeiro"
                      :style="`${
                        qualification.tipo_qualificadores.respostas
                          .vueSelecionados &&
                        qualification.tipo_qualificadores.respostas.vueSelecionados.includes(
                          1
                        )
                          ? 'color: white; background-color: red'
                          : 'color: red; background-color: white'
                      }`"
                    >
                      <b-icon
                        class="h6"
                        icon="emoji-angry"
                        style="margin-top: 2px; width: 1.3rem; height: 1.3rem"
                      ></b-icon>
                    </div>
                    <div
                      @click="cliqueQualificadorCSAT(qualification, 2)"
                      class="lista"
                      :style="`${
                        qualification.tipo_qualificadores.respostas
                          .vueSelecionados &&
                        qualification.tipo_qualificadores.respostas.vueSelecionados.includes(
                          2
                        )
                          ? 'color: white; background-color: #ca6702'
                          : 'color: #ca6702; background-color: white'
                      }`"
                    >
                      <b-icon
                        class="h6"
                        icon="emoji-frown"
                        style="margin-top: 2px; width: 1.3rem; height: 1.3rem"
                      ></b-icon>
                    </div>
                    <div
                      @click="cliqueQualificadorCSAT(qualification, 3)"
                      class="lista"
                      :style="`${
                        qualification.tipo_qualificadores.respostas
                          .vueSelecionados &&
                        qualification.tipo_qualificadores.respostas.vueSelecionados.includes(
                          3
                        )
                          ? 'color: white; background-color: #fca311'
                          : 'color: #fca311; background-color: white'
                      }`"
                    >
                      <b-icon
                        class="h6"
                        icon="emoji-neutral"
                        style="margin-top: 2px; width: 1.3rem; height: 1.3rem"
                      ></b-icon>
                    </div>
                    <div
                      @click="cliqueQualificadorCSAT(qualification, 4)"
                      class="lista"
                      :style="`${
                        qualification.tipo_qualificadores.respostas
                          .vueSelecionados &&
                        qualification.tipo_qualificadores.respostas.vueSelecionados.includes(
                          4
                        )
                          ? 'color: white; background-color: #6a994e'
                          : 'color: #6a994e; background-color: white'
                      }`"
                    >
                      <b-icon
                        class="h6"
                        icon="emoji-smile"
                        style="margin-top: 2px; width: 1.3rem; height: 1.3rem"
                      ></b-icon>
                    </div>
                    <div
                      @click="cliqueQualificadorCSAT(qualification, 5)"
                      class="lista ultimo"
                      :style="`${
                        qualification.tipo_qualificadores.respostas
                          .vueSelecionados &&
                        qualification.tipo_qualificadores.respostas.vueSelecionados.includes(
                          5
                        )
                          ? 'color: white; background-color: green'
                          : 'color: green; background-color: white'
                      }`"
                    >
                      <b-icon
                        class="h6"
                        icon="emoji-laughing"
                        style="margin-top: 2px; width: 1.3rem; height: 1.3rem"
                      ></b-icon>
                    </div>
                  </div>

                  <div
                    v-else-if="
                      qualification.tipo_qualificadores.nome === 'RATING'
                    "
                  >
                    <div class="d-flex">
                      <div
                        v-for="n in 5"
                        :key="n"
                        @click="cliqueQualificadorEstrela(qualification, n)"
                        class="lista"
                        :class="{ primeiro: n == 1, ultimo: n == 5 }"
                        :style="estiloEstrela(qualification, n)"
                      >
                        <b-icon
                          class="h6"
                          :icon="`star${
                            qualification.tipo_qualificadores.respostas
                              .vueSelecionados &&
                            qualification.tipo_qualificadores.respostas
                              .vueSelecionados[0] >= n
                              ? '-fill'
                              : ''
                          }`"
                          style="margin-top: 2px; width: 1.3rem; height: 1.3rem"
                        ></b-icon>
                      </div>
                    </div>
                  </div>

                  <div
                    v-else-if="
                      qualification.tipo_qualificadores.nome === 'RATING_NA'
                    "
                  >
                    <div class="d-flex">
                      <div
                        @click="cliqueQualificadorEstrela(qualification, 'NA')"
                        class="lista primeiro"
                        :style="` ${
                          !qualification.value
                            ? 'background-color: #9d9d9d; color: white;'
                            : 'background-color: white; color: black;'
                        };`"
                      >
                        N/A
                      </div>
                      <div
                        v-for="n in 5"
                        :key="n"
                        @click="cliqueQualificadorEstrela(qualification, n)"
                        class="lista"
                        :class="{ ultimo: n == 5 }"
                        :style="estiloEstrela(qualification, n)"
                      >
                        <b-icon
                          class="h6"
                          :icon="`star${
                            qualification.tipo_qualificadores.respostas
                              .vueSelecionados &&
                            qualification.tipo_qualificadores.respostas
                              .vueSelecionados[0] >= n
                              ? '-fill'
                              : ''
                          }`"
                          style="margin-top: 2px; width: 1.3rem; height: 1.3rem"
                        ></b-icon>
                      </div>
                    </div>
                  </div>

                  <div v-else class="toggle">
                    <input
                      type="radio"
                      v-model="qualification.value"
                      :name="[[qualification.name]]"
                      value="N"
                      :id="[[radioIdx]] + 'Like'"
                      class="dislike"
                    />
                    <label :for="[[radioIdx]] + 'Like'" class="dislike">
                      <i
                        class="v-icon pl-1"
                        v-html="
                          $MyIcons.icon(
                            'dislike-outline',
                            qualification.value === 'N' ? null : '#c02416e6'
                          )
                        "
                      />
                    </label>
                    <input
                      type="radio"
                      v-model="qualification.value"
                      :name="[[qualification.name]]"
                      value="P"
                      :id="[[radioIdx]] + 'Dislike'"
                    />
                    <label :for="[[radioIdx]] + 'Dislike'">
                      <i
                        class="v-icon pl-1"
                        v-html="
                          $MyIcons.icon(
                            'like-outline',
                            qualification.value === 'P' ? null : '#39a567'
                          )
                        "
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="a-divider" />
          </div>
        </div>
        <div
          :class="`${
            setting.qualifiers.length > 6 ? 'col-md-8' : 'col-md-5'
          } col-sm-12 ${
            setting.qualifiers.length > 6 ? 'pt-md-0' : 'p-md-5'
          } mt-md-4 mb-md-5`"
          style="margin: auto"
        >
          <header class="layout-a mb-2">
            <h3 class="a-subtitle-small m-0">
              Agradecemos por contribuir com a nossa melhoria contínua!
            </h3>
          </header>

          <p>
            Caso tenha algo mais a dizer, gostaríamos de receber o seu
            comentário.
          </p>

          <div class="d-flex d-md-block form-group">
            <textarea
              v-model="message"
              class="form-control a-text-area"
              rows="3"
            ></textarea>
          </div>

          <div class="d-md-block text-center">
            <a
              @click="finishForm()"
              v-bind:style="{ borderColor: setting.unidade.cor }"
              v-bind:class="{
                'a-btn a-btn-success':
                  setting.layout === 'v' || setting.layout === 'a',
                'c-btn c-btn-success': setting.layout === 'c',
              }"
              class="btn"
            >
              Concluir
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import MultiSelect from "primevue/multiselect";
import moment from "moment";
import { ApiComunicante } from "@/services/apiComunicante";

export default {
  components: {
    MultiSelect,
  },
  props: {
    grade: Number,
  },
  data() {
    return {
      message: null,
      URL_APP: process.env.VUE_APP_URL_APP,
      atualizaCampoQualificadores: 1,
      dropdown: false,
      selectedCars: null,
      tempoInicio: "",
      tempoFinal: "",
      cars: [
        { brand: "Audi", value: "Audi" },
        { brand: "BMW", value: "BMW" },
        { brand: "Fiat", value: "Fiat" },
        { brand: "Honda", value: "Honda" },
        { brand: "Jaguar", value: "Jaguar" },
        { brand: "Mercedes", value: "Mercedes" },
        { brand: "Renault", value: "Renault" },
        { brand: "Volkswagen", value: "Volkswagen" },
        { brand: "Volvo", value: "Volvo" },
      ],
    };
  },
  computed: {
    ...mapState({
      setting: (state) => state.app.configs,
    }),
  },
  mounted() {
    this.tempoInicio = moment().format("YYYY-MM-DD HH:mm:ss");
  },
  methods: {
    async finishForm(message) {
      // this.tratarQualificadoresEnvio()

      this.tempoFinal = moment().format("YYYY-MM-DD HH:mm:ss");
      ApiComunicante.enviarEstatistica({
        contaId: this.setting.conta_id,
        dataInicio: this.tempoInicio,
        dataFim: this.tempoFinal,
        uid: this.setting.uid,
        recursoTipoId: this.$route.name == "Qr Code" ? 21 : 13,
        titulo: this.$route.name == "Qr Code" ? "QR Code" : "Pesquisa",
        descricao: "Layout " + this.setting.layout.toUpperCase(),
        origemChamada: "",
      });

      this.updateMessage(message);
      this.$emit("finish:form");
    },
    updateMessage() {
      this.$emit("update:message", this.message);
    },

    onClickBadge(qualificador, valor) {
      if (!qualificador.vueOpcoesSelecionadas)
        qualificador.vueOpcoesSelecionadas = [];
      qualificador.vueOpcoesSelecionadas.find();
      qualificador.vueOpcoesSelecionadas.push(valor);
    },

    cliqueQualificadorLista(qualificador, opcao) {
      const r = qualificador.tipo_qualificadores.respostas;
      if (!r.vueSelecionados) r.vueSelecionados = [];
      if (!r.multi) r.vueSelecionados = [];
      const index = r.vueSelecionados.indexOf(opcao);
      if (index < 0) r.vueSelecionados.push(opcao);
      else r.vueSelecionados.splice(index, 1);
      if (r.vueSelecionados.length) {
        qualificador.value = JSON.stringify(
          r.vueSelecionados.map((i) => i.name)
        );
      }
      this.atualizarCampoQualificadores();
    },

    cliqueQualificadorCSAT(qualificador, opcao) {
      const r = qualificador.tipo_qualificadores.respostas;
      r.vueSelecionados = [];
      const index = r.vueSelecionados.indexOf(opcao);
      if (index < 0) r.vueSelecionados.push(opcao);
      else r.vueSelecionados.splice(index, 1);
      if (r.vueSelecionados.length) {
        qualificador.value = r.vueSelecionados[0];
      }
      this.atualizarCampoQualificadores();
    },

    cliqueQualificadorEstrela(qualificador, opcao) {
      const r = qualificador.tipo_qualificadores.respostas;
      r.vueSelecionados = [];
      const index = r.vueSelecionados.indexOf(opcao);
      if (index < 0) r.vueSelecionados.push(opcao);
      else r.vueSelecionados.splice(index, 1);
      if (opcao == "NA") {
        delete qualificador.value;
      } else if (r.vueSelecionados.length) {
        qualificador.value = r.vueSelecionados[0];
      }
      this.atualizarCampoQualificadores();
    },

    cliqueQualificadorListaDropdown(event) {
      event.stopPropagation();
    },

    atualizarCampoQualificadores() {
      this.atualizaCampoQualificadores += 1;
    },

    estiloEstrela(qualificador, n) {
      if (qualificador.tipo_qualificadores.respostas.vueSelecionados) {
        return (
          "transition: all 1s ease-out; " +
          `${
            qualificador.tipo_qualificadores.respostas.vueSelecionados[0] >= n
              ? "color: white; background-color: #ffc700"
              : "color: black; background-color: white"
          }`
        );
      }
    },

    mudancaoDropdown(qualificador) {
      const r = qualificador.tipo_qualificadores.respostas;
      if (!r.vueSelecionados) r.vueSelecionados = [];
      if (r.vueSelecionados != undefined && r.vueSelecionados.length) {
        qualificador.value = JSON.stringify(
          r.vueSelecionados.map((i) => i.name)
        );
      }
    },

    tratarQualificadoresEnvio() {
      this.setting.qualifiers.forEach((i) => {
        const r = i.tipo_qualificadores.respostas;
        if (r.vueSelecionados != undefined && r.vueSelecionados.length) {
          i.value = JSON.stringify(r.vueSelecionados);
        }
      });
    },
  },
};
</script>

<style scoped>
.badge-secundario {
  color: #fff;
  background-color: #6c757d;
}

.promotor-theme {
  background-color: #39a567 !important;
}

.neutro-theme {
  background-color: #ffcc00 !important;
}

.detrator-theme {
  background-color: #c6392d !important;
}

.lista {
  font-weight: 400;
  border: solid 1px #ddd;
  box-shadow: -1px 0px 5px rgb(209, 209, 209);
  padding: 1px 15px;
  height: 30px;
  user-select: none;
}

.lista:hover {
  border: solid 1px rgb(142, 191, 248);
  box-shadow: -1px 0px 5px rgb(105, 175, 255);
}

.primeiro {
  border-radius: 80px 0 0 80px;
  border-right: none;
}

.ultimo {
  border-radius: 0 80px 80px 0;
  border-left: none;
}

.selecionado {
  color: white;
  background-color: rgb(114, 170, 245);
  border: solid 1px rgb(114, 170, 245);
}

.p-multiselect {
  width: 15rem;
  border: solid 1px #ddd;
  box-shadow: -1px 0px 5px rgb(209, 209, 209);
  border-radius: 20px;
  padding: 0 10px;
}

.a-title-note {
  font-size: 3em !important;
  line-height: 1.35em;
  color: white;
  font-weight: 400;
  text-shadow: -2px 2px #333;
}

.a-note {
  font-size: 12em !important;
  line-height: 1.35em;
  color: white;
  font-weight: 500;
  text-shadow: -2px 2px #333;
}

.a-divider {
  display: block;
  width: 100%;
  height: 1px;
  margin: 10px auto;
  background: rgba(144, 144, 144, 0.5);
}

.a-text-area {
  border: solid 1px rgba(144, 144, 144, 0.8) !important;
  background: rgba(144, 144, 144, 0.075);
  border-radius: 5px;
  max-height: 150px;
  height: 150px;
}

.a-text-area:focus {
  border: solid 1px #6bd4c8 !important;
}

.a-btn {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #fff;
  box-shadow: none !important;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 400;
  font-family: "Source Sans Pro";
  /*height: 2.75em;*/
  letter-spacing: 0.125em;
  line-height: 2.75em;
  padding: 0 2em;
  text-align: center;
  text-decoration: none;
  text-indent: 0.125em;
  text-transform: uppercase;
  white-space: nowrap;

  height: 2.75em;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.c-btn {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(51, 51, 51, 0.75);
  box-shadow: none !important;
  color: #333 !important;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 400;
  font-family: "Source Sans Pro";
  /*height: 2.75em;*/
  letter-spacing: 0.125em;
  line-height: 2.75em;
  padding: 0 2em;
  text-align: center;
  text-decoration: none;
  text-indent: 0.125em;
  text-transform: uppercase;
  white-space: nowrap;

  height: 2.75em;
  border-bottom-color: rgba(31, 31, 31, 0.75);
}

a.a-btn:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.125);
}

a.c-btn:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.125);
}

.a-btn:hover {
  background-color: rgba(255, 255, 255, 0.125);
  border-color: #fff;
}

.c-btn:hover {
  background-color: rgba(255, 255, 255, 0.125);
  border-color: #999;
}

.a-btn-success {
  color: #555 !important;
}

.c-btn-success {
  color: #555 !important;
}

header.layout-a:after {
  background: rgba(144, 144, 144, 0.5);
  content: "";
  display: inline-block;
  height: 1px;
  width: 6em;
}

header > .a-subtitle-small {
  font-size: 1.2em;
  line-height: 1.35em;
  color: #555;
  font-weight: 300;
}

header > .a-title-small {
  font-size: 1.5em;
  font-weight: 300;
}

/* star rating */
.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

@media screen and (max-width: 600px) {
  .a-title-note {
    font-size: 2em !important;
    line-height: 1.15em;
    color: white;
    font-weight: 400;
    text-shadow: -1px 1px #333;
    display: inline;
    vertical-align: 19px;
  }

  .a-note {
    font-size: 5em !important;
    line-height: 1.15em;
    color: white;
    font-weight: 500;
    text-shadow: -1px 1px #333;
    display: inline;
  }

  .responsive-spacing {
    margin-top: 12px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .a-title-note {
    font-size: 2.7em !important;
    line-height: 1.35em;
    color: white;
    font-weight: 400;
    text-shadow: -2px 2px #333;
  }

  .a-note {
    font-size: 7.5em !important;
    line-height: 1.35em;
    color: white;
    font-weight: 500;
    text-shadow: -2px 2px #333;
  }

  .mv-badge {
    color: #c6392d;
    border: 3px solid black;
    background-color: red !important;
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import privacyPolicies from '@/pages/privacyPolicies.vue'
import MainNavbar from '@/layout/l/MainNavbar.vue'
import MainFooter from '@/layout/MainFooter.vue'
import Page404 from '@/pages/404'
import landingA from '@/pages/landingA'
import termosDeUso from '@/pages/termosDeUso'


Vue.use(Router)

export default new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      // components: { default: Page404},
      props: {
        header: { colorOnScroll: .1 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/:id/qr/:setor',
      name: 'Qr Code',
      components: { default: landingA }
    },
    {
      path: '/:id/:hash/:nota(\\d+)?',
      name: 'a',
      components: { default: landingA }
    },
    {
      path: '/:id/qr/:setor/:opine',
      name: 'Qr Code',
      components: { default: landingA },
    },
    {
      path: '/:id/preview/:type/:unisetor/:layout',
      name: 'Preview',
      components: { default: landingA },
    },
    {
      path: '/404/',
      name: 'Page404',
      components: { default: Page404 }
    },
    {
      path: '/termos-de-uso',
      name: 'termos',
      components: {default: termosDeUso, header: MainNavbar, footer: MainFooter},
      props: {
        header: { colorOnScroll: 0 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/privacy-policies',
      name: 'privacyPolicies',
      components: {default: privacyPolicies, header: MainNavbar, footer: MainFooter},
      props: {
        header: { colorOnScroll: 0 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
});

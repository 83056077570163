<template>
  <div class="page-body">
    <div class="section cookies-section container">
      <h3>Política de Privacidade</h3>

      <p>
        Por este documento, asseguramos e garantimos que todas as informações dos nossos usuários, não serão comercializadas ou divulgadas em nenhuma circunstância. Seguimos todos os protocolos de segurança recomendados e não medimos esforços para preservá-los de qualquer desvio.
      </p>

      <p>
        Suas informações serão armazenadas, processadas, acessadas, desde que seja respeitada a legislação brasileira, principalmente as disposições da Lei nº 12.965/14 (Marco Civil da Internet) e do Decreto nº 8.771, de 11/05/16. Os dados pessoais que você informar, serão criptografados ao serem armazenados em nosso banco de dados, que é reservado e com acesso restrito.
      </p>

      <p>
        Nossos servidores estão na AWS, bem protegidos por políticas de segurança, assegurando a privacidade, autenticidade e inviolabilidade das informações, conforme determina o Marco Civil da Internet.
      </p>

      <p>
        Poderemos alterar esta Política de Privacidade a qualquer tempo, com ou sem aviso prévio. Pedimos que periodicamente você acesse este documento, para que possa manter-se atualizado e ser informado sobre qualquer alteração.
      </p>

      <p>
        Os elementos e ferramentas do Site/Web e dos Mobiles (iOS e Android) são de nossa titularidade ou são licenciados por nós, nos termos da legislação vigente. A utilização de qualquer elemento ou ferramenta do Site ou dos Mobiles (iOS e Android) apenas poderá ser feita com nossa concordância por escrito.
      </p>

      <p>
        Disposições finais
      </p>

      <p>
        Esta Política de privacidade é regida pelas leis brasileiras. Eventuais controvérsias existentes em relação a ele ou ao uso do Site serão solucionados entre o cliente e a EMPRESA LTDA. no Foro da Comarca de Feira de Santana/BA, mesmo que haja outro mais privilegiado.
        Podemos não exigir de Você o cumprimento de alguma cláusula, o que não representará uma renúncia de direito ou cláusula. Caso algum item desta Política de privacidade venha a ser declarada nula ou não aplicável, os outros termos continuarão se aplicando e permanecerão em vigor e efeito.
      </p>

      <br><br>
      <p>EMPRESA, julho de 2021.</p>
      <br>

      <h3>
        Política de Cookies
      </h3>

      <p>
        Para a <b>EMPRESA</b> LTDA. transparência é fundamental, por isso, preparamos esta Política, para informar de forma clara e direta como e em que momentos nós utilizamos cookies em nossos sites e plataformas.
      </p>

      <h4>
        O que são Cookies?
      </h4>

      <p>
        São pequenos arquivos com dados que os sites solicitam que os navegadores armazenem, permitindo que se identifique o seu dispositivo de acesso. Os cookies possibilitam que os sites “se lembrem” de você usuário e tornam possível diversas atividades durante a utilização do site.
      </p>

      <h4>Tipos de Cookies</h4>
      <h5>Cookies persistentes e não-persistentes</h5>

      <p>Quanto à duração de armazenamento, os cookies podem ser classificados em persistentes e não-persistentes:</p>

      <p>
        Os cookies não-persistentes são utilizados apenas enquanto você navega em nossos sites.Os próprios navegadores apagam os cookies não-persistentes logo após a sessão ser encerrada.<br>
        Os cookies persistentes permanecem em seu navegador por maior período, sendo mantidos mesmo após o término da navegação do usuário em nossos sites.<br>
        Utilizamos em nossos sites tanto cookies persistentes quanto não-persistentes. <br>
      </p>

      <h5>
        Cookies próprios e de terceiros
      </h5>

      <p>
        Os cookies também podem ser classificados quanto a quem o administra. <br>
        Os cookies próprios são aqueles emitidos pela <b>EMPRESA</b>; <br>
        Os cookies de terceiros são emitidos e administrados por empresas parceiras. <br>
        Utilizamos cookies próprios e de terceiros em todos os nossos sites. <br>
      </p>

      <h4>
        Por qual motivo a <b>EMPRESA</b> utiliza cookies?
      </h4>

      <p>
        Utilizamos cookies para diversas funções como possibilitar e facilitar a utilização dos nossos sites e de suas funcionalidades, diminuir a ocorrência de falhas durante o acesso, garantir uma navegação mais personalizável, segmentar a publicidade que exibimos, analisar de forma estatística as preferências gerais do conjunto de usuários de nossos sites.
      </p>

      <h4>
        Quando os cookies são utilizados?
      </h4>

      <p>
        Durante a navegação em todos os nossos sites ocorrerá a coleta e utilização de cookies.
      </p>

      <h4>
        Quais cookies são utilizados pela <b>EMPRESA</b>?
      </h4>

      <p>
        Classificamos, de acordo com o uso, os cookies em: necessários, de segurança, performance, funcionais e de marketing.
      </p>

      <p>
        Vejamos as características de cada um desses tipos de cookies:
      </p>

      <h5>
        Necessários
      </h5>

      <p>
        São cookies essenciais para o correto funcionamento dos nossos sites. São responsáveis, por exemplo, pela manutenção de sessões ativas ou a definição de opções de configuração.<br>
        <span class="font-weight-bold">
            Esses cookies não o identificam, e caso você opte por bloqueá-los, nossos sites poderão ficar indisponíveis para você.
          </span>
      </p>

      <h5>
        Performance
      </h5>

      <p>
        Esse tipo de cookie permite que a <b>EMPRESA</b> compreenda como você interage com nossas páginas. São realizadas coletas de dados de performance como número de visitas, áreas acessadas, duração de acessos e mensagens de erro.
        <br><span class="font-weight-bold">Os cookies de performance são coletados e tratados de forma anônima e caso você os desative, nossos sites poderão ficar indisponíveis para você.</span>
      </p>

      <h5>
        Funcionais
      </h5>

      <p>
        São cookies que permitem funcionalidades de personalização para os usuários dos sites, guardando e fornecendo escolhas de navegação, tais como idioma, região, login. Por envolver suas opções e preferências, esses cookies podem, dependendo da circunstância, ajudar a identificar você.
        <br><span class="font-weight-bold">Caso sejam desativados, algumas opções do site podem ficar indisponíveis.</span>
      </p>

      <h5>
        Segurança
      </h5>

      <p>
        Os cookies de segurança auxiliam a Stone a ativar e oferecer recursos de segurança durante a navegação.
        <br><span class="font-weight-bold">Esses cookies não o identificam, e caso você opte por bloqueá-los, nossos sites poderão ficar indisponíveis para você.</span>
      </p>

      <h5>
        Marketing
      </h5>

      <p>
        Esses cookies possuem a capacidade de direcionar o conteúdo de nossas páginas, de acordo com seu perfil de acesso. Esses cookies coletam alguns de seus dados pessoais.
        <br><span class="font-weight-bold">Caso você os desative, o conteúdo de nossas páginas, inclusive publicidades, não serão baseadas em seu possível interesse.</span>
      </p>

      <h4>
        Não quero utilizar cookies. O que posso fazer?
      </h4>

      <p>
        A melhor forma de não utilizar cookies é por meio da configuração do seu navegador.
      </p>

      <ul>
        <li>Os cookies próprios são aqueles emitidos pela <b>EMPRESA</b>;</li>
        <li>
          Os cookies de terceiros são emitidos e administrados por empresas parceiras.
        </li>
      </ul>

      <p>
        Nesses casos, nossos sites poderão não funcionar corretamente e as suas funcionalidades também poderão estar parcial ou totalmente indisponíveis.
      </p>

      <p>
        Os navegadores permitem que você exclua os cookies que já estejam armazenados em seu computador.
      </p>

      <br>
      <p>Última atualização em 12 de julho de 2021.</p>

      <br><br>
    </div>
  </div>
</template>
<script>
export default {
  name: 'privacy-policies'
};
</script>

<style>
</style>

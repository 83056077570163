<template>
  <div class="landing-page">
    <section class="paggue-hero-background overflow-hidden" style="background-color: #31313b">

      <div class="container d-md-flex flex-column justify-content-around min-vh-100 p-5">
        <h1 class="title  title-404 text-left text-white">Opss...</h1>


        <div class="d-flex justify-content-center img-404">
          <img class="img-title01" src="/img/astro.png" alt="" width="80%"/>
        </div>

        <h2 class="title-small title-small-404 text-center text-white pt-3">Parece que o link que você tentou acessar
          não
          existe ou não está
          disponível
        </h2>
      </div>

    </section>

  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'landingPage',

  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      setting: state => state.app.configs,
    })
  },
};
</script>

<style>

.title-404 {
  font-size: 30px !important;
}

.title-small-404 {
  line-height: 25px !important;
}

@media screen and (min-width: 480px) {

  .title-404 {
    font-size: 50px !important;
  }

  .img-404 {
    /*padding: 6em 0 3em 0;
    color: rgba(255, 255, 255, 0.75);
    background-attachment: fixed, fixed, fixed;
    background-position: top left, center center, center center;
    background-size: auto, cover, cover;
    overflow: hidden;
    position: relative;
    text-align: center;
    background-image: url(/img/astro.png);
    width: 80%*/

    margin-top: -180px;
  }
}

</style>

<template>
  <footer
      class="footer hide-on-print"
      v-bind:class="{'v-bg-footer': setting.layout ===  'v', 'a-bg-footer': setting.layout ===  'a', 'c-bg-footer': setting.layout ===  'c'}"
      :style="`background-image: url(/img/a/overlay2.png), url(/img/a/overlay3.svg), linear-gradient(45deg, ${setting.unidade.diz_vc_cor3} 5%, ${setting.unidade.diz_vc_cor2} 30%, ${setting.unidade.diz_vc_cor1}) !important;`" 
      :data-background-color="backgroundColor"
  >
    <div class="container d-md-flex align-items-center mt-2  text-white justify-content-center" v-if="setting.unidade">
      <div class="d-flex justify-content-center justify-content-around">
        <a v-if="setting.unidade.phone" :href="'tel:+55' + setting.unidade.phone" target="__blank" class="m-md-4">
            <i
                class="v-icon pl-1"
                v-html="$MyIcons.icon( 'phone', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.email" :href="'mailto:' + setting.unidade.email" target="__blank" class="m-md-4">
            <i
                class="v-icon pl-1"
                v-html="$MyIcons.icon( 'email', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.site" :href="setting.unidade.site" target="__blank" class="m-md-4">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('web', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.instagram" :href="setting.unidade.instagram" target="__blank" class="m-md-4">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('instagram', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.facebook" :href="setting.unidade.facebook" target="__blank" class="m-md-4">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('facebook', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.twitter" :href="setting.unidade.twitter" target="__blank" class="m-md-4">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('twitter', '#fff', '25px', '25px')"/>
          </a>
      </div>
    </div>

    <div class="container d-flex align-items-center mt-2 text-white justify-content-center">
      <p class="footer-bottom-text m-0 text-center" v-if="setting.unidade && setting.unidade.document">
        {{ setting.unidade.nome }} • CNPJ: {{ setting.unidade.document }}
      </p>
    </div>

    <div class="container d-flex align-items-center mt-2 text-white justify-content-center">
      <p class="text-center m-0">
        <a href="https://wellon.digital/politica-de-privacidade" class="text-decoration-none footer-text text-white text-center">
          Política de Privacidade
        </a>
        |
        <a href="https://wellon.digital/termos-de-uso" class="text-decoration-none footer-text text-white">
          Termos de uso
        </a>
      </p>
    </div>

    <div class="container d-flex align-items-center mt-2 text-white justify-content-center">
      <p class="footer-bottom-copy text-center">
        © {{ year }} Direitos reservados à 
        <a href="https://wellon.digital/" target="_blank" class="link-corp">
          Wellon Digital
        </a>
      </p>
    </div>
  </footer>
</template>
<script>
import {mapState} from "vuex";

export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      URL_APP: process.env.VUE_APP_URL_APP,
    };
  },
  computed: {
    ...mapState({
      setting: state => state.app.configs,
    })
  },
};
</script>
<style>

.a-bg-footer {
  background-color: #4686a0;
  color: rgba(255, 255, 255, 0.75);
  background-attachment: fixed, fixed, fixed;
  background-image: url(/img/a/overlay2.png), url(/img/a/overlay4.svg), linear-gradient(
      45deg, #4361c2, #4fa49a 50%, #9dc66b 95%);
  background-position: top left, center center, center center;
  background-size: auto, cover, cover;
}

.v-bg-footer {
  color: rgba(255, 255, 255, 0.75);
  padding: 6em 0 6em 0;
  background-color: #b32c24;
  background-attachment: fixed, fixed, fixed;
  background-image: url(/img/a/overlay2.png), url(/img/a/overlay4.svg), linear-gradient(
      45deg, #8e251f, #b32c24 50%, #ef4036 95%);
  background-position: top left, center center, center center;
  background-size: auto, cover, cover;
  text-align: center;
}

.c-bg-footer {
  color: rgba(200, 200, 200, 0.75);
  padding: 6em 0 6em 0;
  background-color: #333;
  background-attachment: fixed, fixed, fixed;
  background-position: top left, center center, center center;
  background-size: auto, cover, cover;
  text-align: center;
}

.link-corp {
  text-decoration: dotted;
}


</style>

import axios from 'axios'
import config from '@/appConfig'



export class ApiComunicante { 
    static axiosInstance = axios.create({
        baseURL: config.URL_API_COMUNICANTE,
        headers: {
            'Authorization': `Bearer ${config.TOKEN_COMUNICANTE}`
        }
    
    })
    
    static enviarEstatistica(data) {
        return this.axiosInstance.post('estatistica', data)
    }
}
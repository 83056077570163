class MyIcons {
  static icon(name, color, width, height) {
    color = color || '#ffffff'
    width = width || '20px'
    height = height || '20px'

    if (name === 'account-edit-outline') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M2 17V20H10V18.11H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.96 14.91 11.91 15.04 12.83 15.28L14.35 13.76C12.95 13.29 11.5 13.03 10 13C7.33 13 2 14.33 2 17M10 4C7.79 4 6 5.79 6 8S7.79 12 10 12 14 10.21 14 8 12.21 4 10 4M10 10C8.9 10 8 9.11 8 8S8.9 6 10 6 12 6.9 12 8 11.11 10 10 10M21.7 13.35L20.7 14.35L18.65 12.35L19.65 11.35C19.86 11.14 20.21 11.14 20.42 11.35L21.7 12.63C21.91 12.84 21.91 13.19 21.7 13.4M12 18.94L18.06 12.88L20.11 14.88L14.11 20.95H12V18.94" />
        </svg>`
      )
    } else if (name === 'account-tie') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}"   d="M12,3A4,4 0 0,1 16,7A4,4 0 0,1 12,11A4,4 0 0,1 8,7A4,4 0 0,1 12,3M16,13.54C16,14.6 15.72,17.07 13.81,19.83L13,15L13.94,13.12C13.32,13.05 12.67,13 12,13C11.33,13 10.68,13.05 10.06,13.12L11,15L10.19,19.83C8.28,17.07 8,14.6 8,13.54C5.61,14.24 4,15.5 4,17V21H10L11.09,21H12.91L14,21H20V17C20,15.5 18.4,14.24 16,13.54Z" />
        </svg>`
      )
    } else if (name === 'account-group') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}"  d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
        </svg>`
      )
    } else if (name === 'alert') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
        </svg>`
      )
    } else if (name === 'archive-check-outline') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M21 3H3V9H21V3M19 7H5V5H19V7M14.5 11C14.78 11 15 11.22 15 11.5V13H9V11.5C9 11.22 9.22 11 9.5 11H14.5M18 13.09V10H20V13.09C19.67 13.04 19.34 13 19 13C18.66 13 18.33 13.04 18 13.09M13 19C13 19.7 13.13 20.37 13.35 21H4V10H6V19H13M22.5 17.25L17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25Z" />
        </svg>`
      )
    } else if (name === 'axis-z-rotate-counterclockwise') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M10,12L14,16L10,20V16.9C5.44,16.44 2,14.42 2,12C2,9.58 5.44,7.56 10,7.1V9.09C6.55,9.43 4,10.6 4,12C4,13.4 6.55,14.57 10,14.91V12M20,12C20,10.6 17.45,9.43 14,9.09V7.1C18.56,7.56 22,9.58 22,12C22,14.16 19.26,16 15.42,16.7L16.12,16L14.92,14.79C17.89,14.36 20,13.27 20,12M11,2H13V13L11,11V2M11,22V21L13,19V22H11Z" />
        </svg>`
      )
    } else if (name === 'bank') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
        <path fill="${color}" d="M11.5,1L2,6V8H21V6M16,10V17H19V10M2,22H21V19H2M10,10V17H13V10M4,10V17H7V10H4Z" />
      </svg>`
      )
    } else if (name === 'bookmark') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
        <path fill="${color}" d="M17,3H7A2,2 0 0,0 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z" />
      </svg>`
      )
    } else if (name === 'calendar-week') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}"  d="M6 1H8V3H16V1H18V3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H6V1M5 8V19H19V8H5M7 10H17V12H7V10Z" />
        </svg>`
      )
    } else if (name === 'chart-areaspline') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M17.45,15.18L22,7.31V19L22,21H2V3H4V15.54L9.5,6L16,9.78L20.24,2.45L21.97,3.45L16.74,12.5L10.23,8.75L4.31,19H6.57L10.96,11.44L17.45,15.18Z" />
        </svg>`
      )
    } else if (name === 'chart-bar') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M22,21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z" />
        </svg>`
      )
    } else if (name === 'chart-gantt') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M2,5H10V2H12V22H10V18H6V15H10V13H4V10H10V8H2V5M14,5H17V8H14V5M14,10H19V13H14V10M14,15H22V18H14V15Z" />
        </svg>`
      )
    } else if (name === 'chart-line') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z" />
        </svg>`
      )
    } else if (name === 'check') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}"  d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </svg>`
      )
    } else if (name === 'chevron-down') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>`
      )
    } else if (name === 'chevron-right') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </svg>`
      )
    } else if (name === 'chevron-up') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
        </svg>`
      )
    } else if (name === 'city') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z" />
        </svg>`
      )
    } else if (name === 'clock-outline') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
        </svg>`
      )
    } else if (name === 'close-circle-outline') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" " d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
        </svg>`
      )
    } else if (name === 'comment-text') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M5,5V7H19V5H5M5,9V11H13V9H5M5,13V15H15V13H5Z" />
        </svg>`
      )
    } else if (name === 'delete') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
             <path fill="${color}" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
        </svg>`
      )
    } else if (name === 'domain') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z" />
        </svg>`
      )
    } else if (name === 'email') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
        </svg>`
      )
    } else if (name === 'file-excel') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
          <path fill="${color}" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
        </svg>`
      )
    } else if (name === 'file-export') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
          <path fill="${color}" d="M6,2C4.89,2 4,2.9 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13,3.5L18.5,9H13M8.93,12.22H16V19.29L13.88,17.17L11.05,20L8.22,17.17L11.05,14.35" />
        </svg>`
      )
    } else if (name === 'filter') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
          <path fill="${color}" d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
        </svg>`
      )
    } else if (name === 'finance') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
          <path fill="${color}" d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" />
        </svg>`
      )
    } else if (name === 'flag-triangle') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
          <path fill="${color}" d="M7,2H9V22H7V2M19,9L11,14.6V3.4L19,9Z" />
        </svg>`
      )
    } else if (name === 'help-circle') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
        </svg>`
      )
    } else if (name === 'information') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>`
      )
    } else if (name === 'menu') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
        </svg>`
      )
    } else if (name === 'microsoft-internet-explorer') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M13,3L14,3.06C16.8,1.79 19.23,1.64 20.5,2.92C21.5,3.93 21.58,5.67 20.92,7.72C21.61,9 22,10.45 22,12L21.95,13H9.08C9.45,15.28 11.06,17 13,17C14.31,17 15.47,16.21 16.2,15H21.5C20.25,18.5 16.92,21 13,21C11.72,21 10.5,20.73 9.41,20.25C6.5,21.68 3.89,21.9 2.57,20.56C1,18.96 1.68,15.57 4,12C4.93,10.54 6.14,9.06 7.57,7.65L8.38,6.88C7.21,7.57 5.71,8.62 4.19,10.17C5.03,6.08 8.66,3 13,3M13,7C11.21,7 9.69,8.47 9.18,10.5H16.82C16.31,8.47 14.79,7 13,7M20.06,4.06C19.4,3.39 18.22,3.35 16.74,3.81C18.22,4.5 19.5,5.56 20.41,6.89C20.73,5.65 20.64,4.65 20.06,4.06M3.89,20C4.72,20.84 6.4,20.69 8.44,19.76C6.59,18.67 5.17,16.94 4.47,14.88C3.27,17.15 3,19.07 3.89,20Z" />
        </svg>`
      )
    } else if (name === 'facebook') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
        </svg>`
      )
    } else if (name === 'instagram') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
        </svg>`
      )
    } else if (name === 'twitter') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
        </svg>`
      )
    } else if (name === 'chrome') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M12,20L15.46,14H15.45C15.79,13.4 16,12.73 16,12C16,10.8 15.46,9.73 14.62,9H19.41C19.79,9.93 20,10.94 20,12A8,8 0 0,1 12,20M4,12C4,10.54 4.39,9.18 5.07,8L8.54,14H8.55C9.24,15.19 10.5,16 12,16C12.45,16 12.88,15.91 13.29,15.77L10.89,19.91C7,19.37 4,16.04 4,12M15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9A3,3 0 0,1 15,12M12,4C14.96,4 17.54,5.61 18.92,8H12C10.06,8 8.45,9.38 8.08,11.21L5.7,7.08C7.16,5.21 9.44,4 12,4M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>`
      )
    } else if (name === 'web') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>`
      )
    } else if (name === 'message-draw') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M18,14H10.5L12.5,12H18M6,14V11.5L12.88,4.64C13.07,4.45 13.39,4.45 13.59,4.64L15.35,6.41C15.55,6.61 15.55,6.92 15.35,7.12L8.47,14M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z" />
        </svg>`
      )
    } else if (name === 'plus') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}"  d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </svg>`
      )
    } else if (name === 'phone') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
        </svg>`
      )
    } else if (name === 'phone-call') {
      return (
        `<svg style="width:${width};height:${height}" fill="none"  stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
            <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
        </svg>`
      )
    } else if (name === 'phone-communication') {
      return (
        `<svg style="width:${width};height:${height}" fill="none" stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 48 48">
            <path d="M26 18h-4v4h4v-4zm8 0h-4v4h4v-4zm6 13c-2.49 0-4.89-.4-7.14-1.14-.69-.22-1.48-.06-2.03.49l-4.4 4.41c-5.67-2.88-10.29-7.51-13.18-13.17l4.4-4.41c.55-.55.71-1.34.49-2.03-.74-2.25-1.14-4.66-1.14-7.15 0-1.11-.89-2-2-2h-7c-1.11 0-2 .89-2 2 0 18.78 15.22 34 34 34 1.11 0 2-.89 2-2v-7c0-1.11-.89-2-2-2zm-2-13v4h4v-4h-4z" />
        </svg>`
      )
    } else if (name === 'phone-interaction') {
      return (
        `<svg style="width:${width};height:${height}" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xml:space="preserve" transform="rotate(-45)">
            <g id="Layer_2"/><g id="Layer_3"/><g id="Layer_4"/><g id="Layer_5"/><g id="Layer_6"/><g id="Layer_7"/><g id="Layer_8"/><g id="Layer_9"/><g id="Layer_10"/><g id="Layer_11"/><g id="Layer_12"/><g id="Layer_13"/><g id="Layer_14"/><g id="Layer_15"/><g id="Layer_16"/><g id="Layer_17"/><g id="Layer_18"/><g id="Layer_19"/><g id="Layer_20"/><g id="Layer_21"/><g id="Layer_22"/><g id="Layer_23"/><g id="Layer_24"/>
            <g id="Layer_25">
                <path fill="${color}" d="M18,14c-2.206,0-4,1.794-4,4c0,0.526,0.108,1.025,0.293,1.486c-4.741-1.305-8.474-5.038-9.779-9.779   C4.975,9.892,5.474,10,6,10c2.206,0,4-1.794,4-4S8.206,2,6,2S2,3.794,2,6c0,8.822,7.178,16,16,16c2.206,0,4-1.794,4-4   S20.206,14,18,14z M6,4c1.103,0,2,0.897,2,2S7.103,8,6,8S4,7.103,4,6S4.897,4,6,4z M18,20c-1.103,0-2-0.897-2-2s0.897-2,2-2   s2,0.897,2,2S19.103,20,18,20z"/>
            </g>
            <g id="Layer_26"/>
        </svg>`
      )
    } else if (name === 'phone-missed') {
      return (
        `<svg style="width:${width};height:${height}" stroke="${color}" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
            <line x1="23" x2="17" y1="1" y2="7"/>
            <line x1="17" x2="23" y1="1" y2="7"/>
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
        </svg>`
      )
    } else if (name === 'reload') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M2 12C2 16.97 6.03 21 11 21C13.39 21 15.68 20.06 17.4 18.4L15.9 16.9C14.63 18.25 12.86 19 11 19C4.76 19 1.64 11.46 6.05 7.05C10.46 2.64 18 5.77 18 12H15L19 16H19.1L23 12H20C20 7.03 15.97 3 11 3C6.03 3 2 7.03 2 12Z" />
        </svg>`
      )
    } else if (name === 'settings') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" - d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z" />
        </svg>`
      )
    } else if (name === 'table') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M5,4H19A2,2 0 0,1 21,6V18A2,2 0 0,1 19,20H5A2,2 0 0,1 3,18V6A2,2 0 0,1 5,4M5,8V12H11V8H5M13,8V12H19V8H13M5,14V18H11V14H5M13,14V18H19V14H13Z" />
        </svg>`
      )
    } else if (name === 'text-account') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M21 5V7H3V5H21M3 17H12V15H3V17M3 12H21V10H3V12M18 14C19.11 14 20 14.9 20 16S19.11 18 18 18 16 17.11 16 16 16.9 14 18 14M14 22V21C14 19.9 15.79 19 18 19S22 19.9 22 21V22H14Z" />
        </svg>`
      )
    } else if (name === 'like-dislike') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M22.5,10H15.75C15.13,10 14.6,10.38 14.37,10.91L12.11,16.2C12.04,16.37 12,16.56 12,16.75V18A1,1 0 0,0 13,19H18.18L17.5,22.18V22.42C17.5,22.73 17.63,23 17.83,23.22L18.62,24L23.56,19.06C23.83,18.79 24,18.41 24,18V11.5A1.5,1.5 0 0,0 22.5,10M12,6A1,1 0 0,0 11,5H5.82L6.5,1.82V1.59C6.5,1.28 6.37,1 6.17,0.79L5.38,0L0.44,4.94C0.17,5.21 0,5.59 0,6V12.5A1.5,1.5 0 0,0 1.5,14H8.25C8.87,14 9.4,13.62 9.63,13.09L11.89,7.8C11.96,7.63 12,7.44 12,7.25V6Z" />
        </svg>`
      )
    } else if (name === 'dislike-outline') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}"  d="M19,15V3H23V15H19M15,3A2,2 0 0,1 17,5V15C17,15.55 16.78,16.05 16.41,16.41L9.83,23L8.77,21.94C8.5,21.67 8.33,21.3 8.33,20.88L8.36,20.57L9.31,16H3C1.89,16 1,15.1 1,14V12C1,11.74 1.05,11.5 1.14,11.27L4.16,4.22C4.46,3.5 5.17,3 6,3H15M15,5H5.97L3,12V14H11.78L10.65,19.32L15,14.97V5Z" />
        </svg>`
      )
    } else if (name === 'like-outline') {
      return (
        `<svg style="width:${width};height:${height}" viewBox="0 0 24 24">
            <path fill="${color}" d="M5,9V21H1V9H5M9,21A2,2 0 0,1 7,19V9C7,8.45 7.22,7.95 7.59,7.59L14.17,1L15.23,2.06C15.5,2.33 15.67,2.7 15.67,3.11L15.64,3.43L14.69,8H21C22.11,8 23,8.9 23,10V12C23,12.26 22.95,12.5 22.86,12.73L19.84,19.78C19.54,20.5 18.83,21 18,21H9M9,19H18.03L21,12V10H12.21L13.34,4.68L9,9.03V19Z" />
        </svg>`
      )
    }
  }

  static promotor(width, height) {
    width = width || 80
    height = height || 155
    return `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
             <defs>
              <style>.cls-1{fill:#83c674;}.cls-2,.cls-6{fill:#fff;}.cls-3{fill:#edecec;}.cls-4{fill:#b5b5b5;}.cls-5{fill:#b7182e;}.cls-6{stroke:#b7182e;stroke-miterlimit:10;stroke-width:4px;}.cls-7{fill:#fcb249;}</style>
             </defs>
             <title>nps-scale</title>
             <g>
              <title>background</title>
              <rect x="-1" y="-1" width="82" height="157" id="canvas_background" fill="none"/>
             </g>
             <g>
              <title>Layer 1</title>
              <g stroke="null" id="Layer_2">
               <circle stroke="null" class="cls-1" cx="39.106792" cy="30.19845" r="15.189556" id="svg_1"/>
               <path stroke="null" class="cls-1" d="m70.089898,15.364421s-17.270878,31.675121 -23.335274,34.557995c-6.292933,2.99235 -36.810803,-6.76928 -41.536625,-8.438792s-8.733383,6.158038 -2.832228,9.223372c4.505446,3.804299 33.823496,12.671873 34.688673,14.77929c0.236699,2.636552 4.954359,13.894357 3.542326,28.22662c-0.08162,2.90112 1.412033,6.86051 1.412033,6.86051s-1.379385,25.015317 -2.930173,26.639215c-3.436219,1.669512 -15.711928,-3.649207 -16.887262,-4.223958c-3.068927,-1.231608 -9.794449,3.074457 -4.717659,10.290765c1.020255,1.496175 16.756669,12.133615 28.322281,5.80224c2.595529,-1.888465 5.664456,2.23514 11.092213,-27.569762c1.020255,0 2.39964,0.136845 2.39964,0.136845s3.028117,8.70336 3.738215,8.967927s-6.374554,1.05827 -2.35883,10.810777s14.871238,8.79459 16.283271,3.95939c1.412033,-4.041497 2.448612,-18.027085 -5.582836,-38.508262c0.628477,-22.597717 -8.978245,-43.434692 -8.978245,-43.434692s11.565612,-22.33315 16.201651,-33.590955c3.787187,-9.168634 -7.778425,-8.438792 -8.52117,-4.488525z" id="svg_2"/>
              </g>
              <g id="Layer_3">
               <path d="m496.92,279.58l4.08,0l0,-3.25l0.89,0l0,8l-0.89,0l0,-3.91l-4.1,0l0,3.91l-0.9,0l0,-8l0.89,0l0.03,3.25z" id="svg_59"/>
               <path d="m504.86,276.33l0,8l-0.86,0l0,-8l0.86,0z" id="svg_60"/>
               <path d="m511.05,280.32l3.33,0l0,0.19a4.41,4.41 0 0 1 -0.77,2.53a3.59,3.59 0 0 1 -3,1.44a4.14,4.14 0 1 1 0,-8.28a4,4 0 0 1 2.45,0.79a4.87,4.87 0 0 1 0.92,0.95l-0.66,0.6a4.26,4.26 0 0 0 -0.76,-0.83a2.93,2.93 0 0 0 -1.94,-0.67a3.3,3.3 0 0 0 0,6.6a2.78,2.78 0 0 0 2.45,-1.34a2.54,2.54 0 0 0 0.32,-1.14l-2.37,0l0.03,-0.84z" id="svg_61"/>
               <path d="m516.91,279.58l4.09,0l0,-3.25l0.89,0l0,8l-0.89,0l0,-3.91l-4.1,0l0,3.91l-0.9,0l0,-8l0.89,0l0.02,3.25z" id="svg_62"/>
               <path d="m524.86,276.33l0,7.16l2.21,0l0,0.84l-3.07,0l0,-8l0.86,0z" id="svg_63"/>
               <path d="m529.55,280.89l-2.63,-4.56l1,0l2,3.57l2.06,-3.57l1,0l-2.65,4.56l0,3.44l-0.89,0l0.11,-3.44z" id="svg_64"/>
               <path d="m537.9,276.33l0,7.16l2.21,0l0,0.84l-3.11,0l0,-8l0.9,0z" id="svg_65"/>
               <path d="m542.2,276.33l0,8l-0.89,0l0,-8l0.89,0z" id="svg_66"/>
               <path d="m545.15,279.76l3.41,-3.43l1.2,0l-3.76,3.73l3.86,4.27l-1.22,0l-3.27,-3.67l-0.23,0.23l0,3.44l-0.89,0l0,-8l0.89,0l0.01,3.43z" id="svg_67"/>
               <path d="m555.22,277.17l-3.22,0l0,2.38l3.17,0l0,0.84l-3.17,0l0,3.11l3.26,0l0,0.84l-4.15,0l0,-8l4.15,0l-0.04,0.83z" id="svg_68"/>
               <path d="m557.75,276.33l0,7.16l2.25,0l0,0.84l-3.09,0l0,-8l0.84,0z" id="svg_69"/>
               <path d="m562.44,280.89l-2.63,-4.56l1,0l2,3.57l2.06,-3.57l1,0l-2.65,4.56l0,3.44l-0.89,0l0.11,-3.44z" id="svg_70"/>
               <path d="m6,284.33l0,-8.33l6.24,6.54l0,-6.2l0.89,0l0,8.36l-6.24,-6.54l0,6.18l-0.89,-0.01z" id="svg_71"/>
               <path d="m14.79,280.34a4.16,4.16 0 1 1 4.21,4.14a4.08,4.08 0 0 1 -4.21,-4.14zm0.91,0a3.25,3.25 0 1 0 3.3,-3.34a3.26,3.26 0 0 0 -3.3,3.34z" id="svg_72"/>
               <path d="m26.3,277.17l0,7.16l-0.89,0l0,-7.16l-1.94,0l0,-0.84l4.77,0l0,0.84l-1.94,0z" id="svg_73"/>
               <path d="m33.13,276.33l0,7.16l2.21,0l0,0.84l-3.1,0l0,-8l0.89,0z" id="svg_74"/>
               <path d="m37.42,276.33l0,8l-0.89,0l0,-8l0.89,0z" id="svg_75"/>
               <path d="m40.38,279.76l3.41,-3.43l1.21,0l-3.74,3.73l3.86,4.27l-1.24,0l-3.27,-3.67l-0.23,0.23l0,3.44l-0.89,0l0,-8l0.89,0l0,3.43z" id="svg_76"/>
               <path d="m50.45,277.17l-3.26,0l0,2.38l3.17,0l0,0.84l-3.17,0l0,3.11l3.26,0l0,0.84l-4.15,0l0,-8l4.15,0l0,0.83z" id="svg_77"/>
               <path d="m53,276.33l0,7.16l2.21,0l0,0.84l-3.12,0l0,-8l0.91,0z" id="svg_78"/>
               <path d="m57.67,280.89l-2.67,-4.56l1,0l2,3.57l2.06,-3.57l1,0l-2.65,4.56l0,3.44l-0.89,0l0.15,-3.44z" id="svg_79"/>
              </g>
             </g>
            </svg>`
  }

  static neutro(width, height) {
    width = width || 80
    height = height || 155
    return `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
               <defs>
                <style>.cls-1{fill:#83c674;}.cls-2,.cls-6{fill:#fff;}.cls-3{fill:#edecec;}.cls-4{fill:#b5b5b5;}.cls-5{fill:#b7182e;}.cls-6{stroke:#b7182e;stroke-miterlimit:10;stroke-width:4px;}.cls-7{fill:#fcb249;}</style>
               </defs>
               <title>nps-scale</title>
               <g>
                <title>background</title>
                <rect fill="none" id="canvas_background" height="157" width="82" y="-1" x="-1"/>
               </g>
               <g>
                <title>Layer 1</title>
                <g id="Layer_2"/>
                <g id="Layer_3">
                 <circle id="svg_30" r="12.81" cy="16.04951" cx="39.54951" class="cls-7"/>
                 <path id="svg_31" d="m40.71951,95.54951c-2.6,0.06 -3,50.17 -3.17,52.83c0,3.63 -13.5,7.21 -13.5,-2.46c-0.67,-3.87 0.5,-89.94 0.67,-91.12c-2,2.31 -4.1,4.75 -3.83,25.08c-0.27,4.42 -12.27,3.54 -12.5,-0.5c-0.2,-1.75 -7,-45.85 31.83,-47.18s30.9,47.34 31.5,45.68c-1.48,6.85 -12.44,7.17 -12.48,1.33c-1,-20.6 -1.56,-25.23 -3.56,-25.92c-0.94,-0.56 1.13,83.67 1.41,92.58c0.41,7.59 -15.13,8.42 -15.53,0.83c0.05,-3.15 1.05,-51.02 -0.84,-51.15z" class="cls-7"/>
                 <path id="svg_59" d="m497.92,279.58l4.08,0l0,-3.25l0.89,0l0,8l-0.89,0l0,-3.91l-4.1,0l0,3.91l-0.9,0l0,-8l0.89,0l0.03,3.25z"/>
                 <path id="svg_60" d="m505.86,276.33l0,8l-0.86,0l0,-8l0.86,0z"/>
                 <path id="svg_61" d="m512.05,280.32l3.33,0l0,0.19a4.41,4.41 0 0 1 -0.77,2.53a3.59,3.59 0 0 1 -3,1.44a4.14,4.14 0 1 1 0,-8.28a4,4 0 0 1 2.45,0.79a4.87,4.87 0 0 1 0.92,0.95l-0.66,0.6a4.26,4.26 0 0 0 -0.76,-0.83a2.93,2.93 0 0 0 -1.94,-0.67a3.3,3.3 0 0 0 0,6.6a2.78,2.78 0 0 0 2.45,-1.34a2.54,2.54 0 0 0 0.32,-1.14l-2.37,0l0.03,-0.84z"/>
                 <path id="svg_62" d="m517.91,279.58l4.09,0l0,-3.25l0.89,0l0,8l-0.89,0l0,-3.91l-4.1,0l0,3.91l-0.9,0l0,-8l0.89,0l0.02,3.25z"/>
                 <path id="svg_63" d="m525.86,276.33l0,7.16l2.21,0l0,0.84l-3.07,0l0,-8l0.86,0z"/>
                 <path id="svg_64" d="m530.55,280.89l-2.63,-4.56l1,0l2,3.57l2.06,-3.57l1,0l-2.65,4.56l0,3.44l-0.89,0l0.11,-3.44z"/>
                 <path id="svg_65" d="m538.9,276.33l0,7.16l2.21,0l0,0.84l-3.11,0l0,-8l0.9,0z"/>
                 <path id="svg_66" d="m543.2,276.33l0,8l-0.89,0l0,-8l0.89,0z"/>
                 <path id="svg_67" d="m546.15,279.76l3.41,-3.43l1.2,0l-3.76,3.73l3.86,4.27l-1.22,0l-3.27,-3.67l-0.23,0.23l0,3.44l-0.89,0l0,-8l0.89,0l0.01,3.43z"/>
                 <path id="svg_68" d="m556.22,277.17l-3.22,0l0,2.38l3.17,0l0,0.84l-3.17,0l0,3.11l3.26,0l0,0.84l-4.15,0l0,-8l4.15,0l-0.04,0.83z"/>
                 <path id="svg_69" d="m558.75,276.33l0,7.16l2.25,0l0,0.84l-3.09,0l0,-8l0.84,0z"/>
                 <path id="svg_70" d="m563.44,280.89l-2.63,-4.56l1,0l2,3.57l2.06,-3.57l1,0l-2.65,4.56l0,3.44l-0.89,0l0.11,-3.44z"/>
                 <path id="svg_71" d="m7,284.33l0,-8.33l6.24,6.54l0,-6.2l0.89,0l0,8.36l-6.24,-6.54l0,6.18l-0.89,-0.01z"/>
                 <path id="svg_72" d="m15.79,280.34a4.16,4.16 0 1 1 4.21,4.14a4.08,4.08 0 0 1 -4.21,-4.14zm0.91,0a3.25,3.25 0 1 0 3.3,-3.34a3.26,3.26 0 0 0 -3.3,3.34z"/>
                 <path id="svg_73" d="m27.3,277.17l0,7.16l-0.89,0l0,-7.16l-1.94,0l0,-0.84l4.77,0l0,0.84l-1.94,0z"/>
                 <path id="svg_74" d="m34.13,276.33l0,7.16l2.21,0l0,0.84l-3.1,0l0,-8l0.89,0z"/>
                 <path id="svg_75" d="m38.42,276.33l0,8l-0.89,0l0,-8l0.89,0z"/>
                 <path id="svg_76" d="m41.38,279.76l3.41,-3.43l1.21,0l-3.74,3.73l3.86,4.27l-1.24,0l-3.27,-3.67l-0.23,0.23l0,3.44l-0.89,0l0,-8l0.89,0l0,3.43z"/>
                 <path id="svg_77" d="m51.45,277.17l-3.26,0l0,2.38l3.17,0l0,0.84l-3.17,0l0,3.11l3.26,0l0,0.84l-4.15,0l0,-8l4.15,0l0,0.83z"/>
                 <path id="svg_78" d="m54,276.33l0,7.16l2.21,0l0,0.84l-3.12,0l0,-8l0.91,0z"/>
                 <path id="svg_79" d="m58.67,280.89l-2.67,-4.56l1,0l2,3.57l2.06,-3.57l1,0l-2.65,4.56l0,3.44l-0.89,0l0.15,-3.44z"/>
                </g>
               </g>
              </svg>`
  }

  static detrator(width, height) {
    width = width || 90
    height = height || 150
    return `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
                 <defs>
                  <style>.cls-1{fill:#83c674;}.cls-2,.cls-6{fill:#fff;}.cls-3{fill:#edecec;}.cls-4{fill:#b5b5b5;}.cls-5{fill:#b7182e;}.cls-6{stroke:#b7182e;stroke-miterlimit:10;stroke-width:4px;}.cls-7{fill:#fcb249;}</style>
                 </defs>
                 <title>nps-scale</title>
                 <g>
                  <title>background</title>
                  <rect x="-1" y="-1" width="92" height="132" id="canvas_background" fill="none"/>
                 </g>
                 <g>
                  <title>Layer 1</title>
                  <g id="Layer_2"/>
                  <g stroke="null" id="Layer_3">
                   <circle stroke="null" class="cls-5" cx="34.498052" cy="23.232075" r="10.12745" id="svg_26"/>
                   <path stroke="null" class="cls-5" d="m32.931304,85.529026c1.203262,0 14.100727,30.098527 14.100727,30.098527c0.106539,1.455929 9.193674,5.830715 10.027184,-4.549777c-0.275748,-1.616921 -15.899353,-31.148475 -16.764198,-33.073381c2.118243,-2.099897 2.017971,-3.05885 2.744942,-6.649675s0.70817,-15.224255 0.70817,-15.224255s5.95364,9.449537 7.363713,14.174306c0.106539,0.363982 1.04032,1.161943 2.237315,1.686917c2.237315,0.979952 5.395878,0.986952 5.753097,-4.661772c1.178194,-3.324837 -17.234222,-29.398561 -19.897693,-30.623501s-10.967232,-0.699966 -15.5108,6.299692c-3.710058,6.068703 -15.404261,9.330543 -16.764198,9.79952s-6.586606,5.424734 -0.006267,8.924563c2.820145,2.274889 16.701528,-6.1247 16.701528,-6.1247l-0.564029,17.149161s-0.313349,2.099897 1.309801,4.640773c-0.996451,1.308936 -13.11681,27.445657 -14.940504,32.632403c-1.516612,5.368737 5.120131,11.143455 9.24381,4.89976c5.533752,-14.699281 13.05414,-29.398561 14.257402,-29.398561z" id="svg_27"/>
                   <polygon stroke="null" class="cls-5" points="52.74752348988932,70.64774062619927 73.35339952126196,110.49678699071137 75.39015457799178,114.40261524210928 80.24707793207017,111.30876237690518 87.24730092055688,106.99696999205753 72.13758822307864,76.56246857816677 63.07553486006691,82.54017146699607 56.119164228162845,69.16382054332189 52.74752348988932,70.64774062619927 " id="svg_28"/>
                   <circle stroke="null" class="cls-6" cx="77.007044" cy="114.402612" r="2.688533" id="svg_29"/>
                   <path stroke="null" d="m250.659065,204.579199l2.556932,0l0,-2.274889l0.557762,0l0,5.599726l-0.557762,0l0,-2.736866l-2.569466,0l0,2.736866l-0.564029,0l0,-5.599726l0.557762,0l0.018801,2.274889z" id="svg_59"/>
                   <path stroke="null" d="m255.635055,202.30431l0,5.599726l-0.538961,0l0,-5.599726l0.538961,0z" id="svg_60"/>
                   <path stroke="null" d="m259.514322,205.097173l2.086908,0l0,0.132993a2.763743,3.086849 0 0 1 -0.482558,1.770913a2.249849,2.512877 0 0 1 -1.880097,1.007951a2.594534,2.897858 0 1 1 0,-5.795716a2.506796,2.799863 0 0 1 1.535413,0.552973a3.052024,3.408833 0 0 1 0.576563,0.664967l-0.413621,0.419979a2.669738,2.981854 0 0 0 -0.476291,-0.580972a1.836228,2.0509 0 0 0 -1.215796,-0.468977a2.068107,2.309887 0 0 0 0,4.619774a1.742223,1.945905 0 0 0 1.535413,-0.937954a1.591815,1.777913 0 0 0 0.200544,-0.797961l-1.485277,0l0.018801,-0.587971z" id="svg_61"/>
                   <path stroke="null" d="m263.186778,204.579199l2.563199,0l0,-2.274889l0.557762,0l0,5.599726l-0.557762,0l0,-2.736866l-2.569466,0l0,2.736866l-0.564029,0l0,-5.599726l0.557762,0l0.012534,2.274889z" id="svg_62"/>
                   <path stroke="null" d="m268.169035,202.30431l0,5.011755l1.385005,0l0,0.587971l-1.923966,0l0,-5.599726l0.538961,0z" id="svg_63"/>
                   <path stroke="null" d="m271.108253,205.496154l-1.648218,-3.191844l0.626699,0l1.253398,2.498878l1.291,-2.498878l0.626699,0l-1.660752,3.191844l0,2.407882l-0.557762,0l0.068937,-2.407882z" id="svg_64"/>
                   <path stroke="null" d="m276.34119,202.30431l0,5.011755l1.385005,0l0,0.587971l-1.949034,0l0,-5.599726l0.564029,0z" id="svg_65"/>
                   <path stroke="null" d="m279.035995,202.30431l0,5.599726l-0.557762,0l0,-5.599726l0.557762,0z" id="svg_66"/>
                   <path stroke="null" d="m280.884757,204.705192l2.137044,-2.400882l0.752039,0l-2.356388,2.610872l2.419058,2.988854l-0.764573,0l-2.049306,-2.568874l-0.144141,0.160992l0,2.407882l-0.557762,0l0,-5.599726l0.557762,0l0.006267,2.400882z" id="svg_67"/>
                   <path stroke="null" d="m287.195616,202.892281l-2.017971,0l0,1.665918l1.986636,0l0,0.587971l-1.986636,0l0,2.176893l2.043039,0l0,0.587971l-2.600801,0l0,-5.599726l2.600801,0l-0.025068,0.580972z" id="svg_68"/>
                   <path stroke="null" d="m288.781165,202.30431l0,5.011755l1.410073,0l0,0.587971l-1.9365,0l0,-5.599726l0.526427,0z" id="svg_69"/>
                   <path stroke="null" d="m291.720383,205.496154l-1.648218,-3.191844l0.626699,0l1.253398,2.498878l1.291,-2.498878l0.626699,0l-1.660752,3.191844l0,2.407882l-0.557762,0l0.068937,-2.407882z" id="svg_70"/>
                   <path stroke="null" d="m-57,207.904036l0,-5.830715l3.910602,4.577776l0,-4.339788l0.557762,0l0,5.851714l-3.910602,-4.577776l0,4.325788l-0.557762,-0.007z" id="svg_71"/>
                   <path stroke="null" d="m-51.491316,205.111173a2.607068,2.911857 0 1 1 2.638403,2.897858a2.556932,2.85586 0 0 1 -2.638403,-2.897858zm0.570296,0a2.036772,2.274889 0 1 0 2.068107,-2.337886a2.043039,2.281888 0 0 0 -2.068107,2.337886z" id="svg_72"/>
                   <path stroke="null" d="m-44.278011,202.892281l0,5.011755l-0.557762,0l0,-5.011755l-1.215796,0l0,-0.587971l2.989354,0l0,0.587971l-1.215796,0z" id="svg_73"/>
                   <path stroke="null" d="m-39.997657,202.30431l0,5.011755l1.385005,0l0,0.587971l-1.942767,0l0,-5.599726l0.557762,0z" id="svg_74"/>
                   <path stroke="null" d="m-37.309118,202.30431l0,5.599726l-0.557762,0l0,-5.599726l0.557762,0z" id="svg_75"/>
                   <path stroke="null" d="m-35.454089,204.705192l2.137044,-2.400882l0.758306,0l-2.343854,2.610872l2.419058,2.988854l-0.777107,0l-2.049306,-2.568874l-0.144141,0.160992l0,2.407882l-0.557762,0l0,-5.599726l0.557762,0l0,2.400882z" id="svg_76"/>
                   <path stroke="null" d="m-29.14323,202.892281l-2.043039,0l0,1.665918l1.986636,0l0,0.587971l-1.986636,0l0,2.176893l2.043039,0l0,0.587971l-2.600801,0l0,-5.599726l2.600801,0l0,0.580972z" id="svg_77"/>
                   <path stroke="null" d="m-27.545148,202.30431l0,5.011755l1.385005,0l0,0.587971l-1.955301,0l0,-5.599726l0.570296,0z" id="svg_78"/>
                   <path stroke="null" d="m-24.618463,205.496154l-1.673286,-3.191844l0.626699,0l1.253398,2.498878l1.291,-2.498878l0.626699,0l-1.660752,3.191844l0,2.407882l-0.557762,0l0.094005,-2.407882z" id="svg_79"/>
                  </g>
                 </g>
                </svg>`
  }
}

// Vue plugin.
const MyIconPlugin = {
  install(Vue, options) {
    Vue.prototype.$MyIcons = MyIcons
  }
}

export default MyIconPlugin

<template>
  <footer
      class="footer hide-on-print bg-footer"
      :class="{ [`footer-${type}`]: type }"
      :data-background-color="backgroundColor"
  >
    <div class="container" v-if="setting.unidade">
      <div class="d-flex flex-wrap justify-content-md-between text-white">
        <div class="col-6 col-md-3">
          <div class="footer-title mb-4 cursor-pointer">
            {{ setting.unidade.nome }}
          </div>

          <div>
            <p>
              <a href="https://wellon.digital/termos-de-uso" class="text-decoration-none footer-text text-white">
                Termos de uso
              </a>
            </p>
            <p>
              <a href="https://wellon.digital/politica-de-privacidade" class="text-decoration-none footer-text text-white">
                Política de Privacidade
              </a>
            </p>
          </div>
        </div>

        <div class="col-6 col-md-3">
          <div class="footer-title mb-4 cursor-pointer">
            Atendimento
          </div>

          <div>
            <p v-if="setting.unidade.phone">
              <a :href="'tel:+55' + setting.unidade.phone" class="text-decoration-none footer-text text-white">
                {{ setting.unidade.phone }}
              </a>
            </p>

            <p v-if="setting.unidade.email">
              <a :href="'mailto:' + setting.unidade.email" class="text-decoration-none footer-text text-white">
                {{ setting.unidade.email }}
              </a>
            </p>
          </div>
        </div>

      </div>
    </div>

    <div class="container d-md-flex align-items-center mt-4 text-white">
      <div class="col-md-7">
        <p class="footer-bottom-text mb-2" v-if="setting.unidade && setting.unidade.document">
          {{ setting.unidade.nome }} • CNPJ: {{ setting.unidade.document }}
        </p>

        <p class="footer-bottom-copy">
          © {{ year }} Direitos reservados à 
          <a href="https://wellon.digital/" target="_blank" class="link-corp">
            Wellon Digital
          </a>
        </p>
      </div>

      <div class="col-md-5" v-if="setting.unidade">
        <div class="d-flex justify-content-center justify-content-md-end">
          <a v-if="setting.unidade.phone" :href="'tel:+55' + setting.unidade.phone" target="__blank" class="mr-4">
            <i
                class="v-icon pl-1"
                v-html="$MyIcons.icon( 'phone', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.email" :href="'mailto:' + setting.unidade.email" target="__blank" class="mr-4">
            <i
                class="v-icon pl-1"
                v-html="$MyIcons.icon( 'email', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.site" :href="setting.unidade.site" target="__blank" class="mr-4">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('web', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.instagram" :href="setting.unidade.instagram" target="__blank" class="mr-4">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('instagram', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.facebook" :href="setting.unidade.facebook" target="__blank" class="mr-4">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('facebook', '#fff', '25px', '25px')"/>
          </a>
          <a v-if="setting.unidade.twitter" :href="setting.unidade.twitter" target="__blank">
            <i  
                class="v-icon pl-1"
                v-html="$MyIcons.icon('twitter', '#fff', '25px', '25px')"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {mapState} from "vuex";

export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      URL_APP: process.env.VUE_APP_URL_APP,
    };
  },
  computed: {
    ...mapState({
      setting: state => state.app.configs,
    })
  },
};
</script>
<style></style>

<template>
  <div class="page-body min-vh-100">
    <div class="section cookies-section container">
      <h3>Termos de uso</h3>
      <p>Termos de uso</p>

      <p>
        A leitura e a concordância dos termos de uso são obrigatórias para o cadastro e uso dos serviços oferecidos pela <b>EMPRESA</b> (<b>EMPRESA</b> LTDA. - Av. Presidente, 5 - Centro, Feira de Santana/BA - Brasil - CNPJ 29.123.420/0001-05). No momento do cadastro, você estará aceitando todas as regras e se você não concordar com os termos, não poderá usar os serviços descritos e oferecidos no site (www.site.io) bem como os seus aplicativos (Apps iOS e Android). Antes de efetuar seu cadastro para utilização dos nossos serviços, você deverá aceitar e concordar com estes termos de uso.
        <br><br>
        Este documento poderá ser periodicamente alterado, conforme a necessidade, para que se mantenha atualizado. Manteremos a versão atualizada destes termos de uso no endereço https://www.site.io/termos-de-uso e o mesmo pode ser consultado a qualquer momento, sendo que poderemos alterar este documento no site, somente visando aprimorar e melhorar os serviços prestados, no todo ou parcialmente, caso seja necessário, independente de prévio aviso. Para seu controle, você poderá ser notificado por uma mensagem via e-mail ou por outro meio de comunicação. Sempre mostramos a data da última versão no final deste termos de uso. Ao continuar usando os serviços após as alterações, você estará concordando com os termos alterados.
      </p>

      <p>
        1. Nossa responsabilidade
        Somos responsáveis por manter os serviços no ar, pelo maior período possível, mas poderão ocorrer casos fora de nosso controle que poderão ocasionar uma indisponibilidade temporária do site (“Casos Fortuitos ou Força Maior”).
        <br><br>
        Faremos nosso melhor para manter a confidencialidade e disponibilidade dos seus dados utilizando sempre as melhores práticas e políticas de segurança da indústria.
        <br><br>
        Não podemos nos responsabilizar por danos causados a você pela utilização de nossos serviços, uma vez que apenas disponibilizamos uma ferramenta, e que o conteúdo por ela gerado é inserido por você.
        <br><br>
        Nossos serviços não garantem resultados específicos, garantias de desempenho ou outra expectativa.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'termos'
};
</script>

<style>
</style>

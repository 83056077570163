import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import NowUiKit from './plugins/now-ui-kit';
import axios from 'axios'
import VueAnimate from 'vue-animate-scroll'
import VueMeta from 'vue-meta'
import { Message } from 'element-ui';
import AOS from 'aos'
import Vuex from 'vuex'
import MyIconPlugin from './plugins/icons'
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css' 


Vue.component('Dialog', Dialog);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'aos/dist/aos.css'

Vue.use(PrimeVue);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueAnimate)
Vue.prototype.$message = Message;
Vue.prototype.$http = axios

Vue.use(VueMeta)

Vue.config.productionTip = process.env.NODE_ENV !== "production"

Vue.use(NowUiKit)

Vue.use(Vuex)
Vue.use(MyIconPlugin)

new Vue({
  router,
  store: store,
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app');

App.store = store

export const URL_SITE = process.env.VUE_APP_URL_SITE
export const URL_API = process.env.VUE_APP_URL_API
export const URL_API_COMUNICANTE = process.env.VUE_APP_URL_API_COMUNICANTE
export const TOKEN_COMUNICANTE = process.env.VUE_APP_TOKEN_COMUNICANTE

export const NO_DATA_TEXT = 'Nenhum registro disponível'

export default {
  URL_API,
  URL_API_COMUNICANTE,
  URL_SITE,
  TOKEN_COMUNICANTE,
  NO_DATA_TEXT,
}

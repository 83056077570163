import axios from 'axios'
import config from '@/appConfig'
import * as Cookies from 'js-cookie'

export const DEFAULT_MESSAGE = {
  SUCCESS: 'Ação realizada',
  ERROR_000: 'Verifique sua conexão com a internet',
  ERROR_400: 'Não foi possível interpretar a solicitação. Verifique a sintaxe das informações enviadas',
  ERROR_401: 'Você precisa fazer login',
  ERROR_403: 'Acesso negado. Este perfil de acesso não permite a ação desejada',
  ERROR_404: 'A página ou recurso solicitado não foi encontrado',
  ERROR_422: 'A solicitação contém campos inválidos',
  ERROR_429: 'O limite de tentativas de acesso foi atingido',
  ERROR_500: 'Ocorreu um erro inesperado. Entre em contato com o atendimento caso o problema persista'
}

/**
 * @param {String} message
 * @returns {Promise<never>}
 */
const promiseReject = (message, error = {}) => Promise.reject({ message, error }) // eslint-disable-line

class Service {
  /** Inject handleSuccess and handleError
   * @param {Promise<any>} httpClientRequest
   * @returns {Promise<any>}
   */
  static injectResponseHandlers (httpClientRequest) {
    return httpClientRequest
      .then(this.handleSuccess)
      .catch(this.handleError)
  }

  /**
   * @param {Object} res HTTP response
   * @param {String} MESSAGE
   * @returns {Promise<any>}
   */
  static handleSuccess ({ data: res }, MESSAGE = DEFAULT_MESSAGE.SUCCESS) {
    let noDataObject = !res.data && res.success ? null : res
    return Promise.resolve({
      data: res.data || noDataObject,
      message: res.message || MESSAGE
    })
  }

  /**
   * Serializar objeto Ex: key_1=string1&key_2=string_2
   * @param params
   * @return object
   * */
  static httpParamSerializer (params) {
    if (!params) params = {}
    params = Object.keys(params).map(k => {
      if (params[k]) return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
    }).join('&')

    return params
  }

  /**
   *  handle Request/Response Errors
   * @param {Object} err HTTP response Error
   * @param {String} MESSAGE
   * @returns {Promise<never>} Promise.reject(error)
   */
  static handleError (err, MESSAGE = DEFAULT_MESSAGE.ERROR_000) {
    // log error
    if (process.browser) console.error(err)
    if (err.response) {
      // Unknow Error / Server Error
      if (!err.response.status) {
        return promiseReject(DEFAULT_MESSAGE.ERROR_000, 0)
      } // eslint-disable-line
      // The request was made and the server responded with a status code
      else if (err.response.data) {
        let { message } = err.response.data
        let { status } = err.response

        if (message) {
          return Promise.reject(err )
          // return promiseReject(message, err)
        }
        else {
          let { status } = err.response
          if (DEFAULT_MESSAGE.hasOwnProperty('ERROR_' + status)) {
            return promiseReject(DEFAULT_MESSAGE['ERROR_' + status], err)
          }
        }
      }
    }

    // The request was made but no response was received
    if (err.request && process.browser) console.error(err.request)

    // Something happened in setting up the request that triggered an Error
    else if (process.browser) console.error(err.message)

    return promiseReject(MESSAGE, 0)
  }
}

const $axiosInstance = axios.create({
  baseURL: config.URL_API,
  headers: {
    // 'Authorization': `Bearer ${Cookies.get('token')}`
  }

})

$axiosInstance.interceptors.response.use(function (response) {
  return Service.handleSuccess(response)
}, function (error) {
  return Service.handleError(error)
})

Service.$axios = $axiosInstance

export { $axiosInstance }

export default Service

<template>
  <div class="min-vh-100 body-l">
    <section
      v-if="setting.unidade.diz_vc_bg"
      class="paggue-hero-l-background"
      v-bind:style="{ backgroundImage: `url(${setting.unidade.diz_vc_bg})` }"
    />

    <MainNavbar :color-on-scroll="0.1" />

    <section v-show="!finish">
      <div class="wrapper">
        <div class="landing-page">
          <section class="paggue-hero-background overflow-hidden">
            <div class="d-md-flex container pt-4 bg-white mb-5">
              <div class="col-md-12 pl-0 pr-0">
                <template v-if="!setting.error">
                  <template v-if="step === 'OS' && !setting.atendimento">
                    <div class="m-sem-bg p-4">
                      <p class="text-center mt-4 m-p">
                        <strong class="m-title text-center"
                          >Para darmos continuidade à sua avaliação precisamos
                          que informe corretamente o número do celular com DDD
                          ou o número do atendimento (ordem de serviço).</strong
                        >
                        <br />Você encontrará essa informação em seu recibo de
                        atendimento. <br />
                        <strong class="m-title text-center"
                          >O formato geralmente é 12X.XXXXXX</strong
                        >
                      </p>
                    </div>

                    <div class="d-flex flex-column align-items-center w-100">
                      <div class="d-md-block m-2">
                        <input
                          v-model="cliente"
                          class="c-input p-2"
                          placeholder="Nome completo (opcional)"
                          maxlength="40"
                          required
                        />
                      </div>

                      <div class="d-md-block m-2">
                        <input
                          v-model="phone"
                          class="c-input p-2"
                          placeholder="Celular (opcional)"
                          required
                        />
                      </div>
                      <div class="d-md-block m-2">
                        <input
                          v-model="atendimento"
                          class="c-input p-2"
                          placeholder="Ordem de serviço"
                          required
                        />
                      </div>
                      <div class="d-md-block">
                        <a
                          @click="avancarAposIdentificacao"
                          class="btn btn-primary m-btn mb-0"
                        >
                          Avançar
                        </a>
                      </div>
                      <div class="mt-4">
                        <a
                          @click="avancarAposIdentificacao"
                          class="btn m-btn m-btn-secondary m-0 ml-2 mb-0"
                        >
                          Avaliar Anonimamente
                        </a>
                      </div>
                    </div>
                  </template>

                  <template v-if="step === 'grade' || setting.atendimento">
                    <p>Prezado(a) {{ setting.cliente }}</p>
                    <p class="text-justify">
                      Agradecemos por ser nosso cliente e esperamos ter superado
                      as suas expectativas durante seu atendimento. Por favor,
                      pedimos que dedique alguns instantes do seu tempo para nos
                      ajudar a melhorar os nossos serviços. Sua opinião é muito
                      importante para nós.
                    </p>
                    <p class="text-justify mt-1">
                      Baseado em sua experiência como cliente, qual é a
                      probabilidade de você recomendar o
                      {{ setting.unidade.nome }} para seus amigos ou contatos?
                    </p>

                    <div class="l-divider" />
                    <div align="center" class="d-flex justify-content-center">
                      <a
                        v-for="i in 11"
                        @click="selecionarGrade(i - 1)"
                        :key="i"
                        class="l-btn"
                        v-bind:class="{
                          'btn-skin-red': i - 1 <= 6,
                          'btn-skin-orange': i - 1 > 6 && i - 1 <= 8,
                          'btn-skin': i - 1 > 8,
                        }"
                        v-bind:style="{
                          backgroundColor:
                            grade === i - 1
                              ? ` ${setting.unidade.cor} !important`
                              : '',
                        }"
                      >
                        {{ i - 1 }}
                      </a>
                    </div>

                    <div class="d-flex row justify-content-around mt-2">
                      <span class="title-small text-left"
                        >0 (NADA PROVÁVEL)</span
                      >
                      <span class="title-small text-left"
                        >10 (MUITO PROVÁVEL)</span
                      >
                    </div>
                  </template>
                </template>

                <!-- <p class="text-justify">
                  {{ setting.error }}
                </p> -->

                <div class="l-divider" />

                <section v-if="setting.unidade.nome_responsavel !== null">
                  <h1 class="subtitle text-left">Atenciosamente,</h1>
                  <h2 class="subtitle text-left mt-3 mb-1">
                    {{ setting.unidade.nome_responsavel }}
                  </h2>
                  <h2 class="subtitle text-left">
                    {{ setting.unidade.cargo_responsavel }}
                  </h2>
                </section>
              </div>
            </div>
          </section>
          <section
            class="l-bg-gray mt-2 paggue-hero-background overflow-hidden"
            v-if="Number.isInteger(grade)"
          >
            <section
              id="qualifiers"
              class="overflow-hidden l-bg-gray mt-0"
              v-show="Number.isInteger(grade) && !finish"
            >
              <Qualifiers
                :grade="grade"
                @update:message="updateMessage"
                @finish:form="concluir"
              />
            </section>
          </section>
        </div>
      </div>
    </section>
    <section v-show="finish">
      <div class="d-md-flex container mb-5">
        <div class="col-md-12 pl-0 pr-0" style="text-align: center">
          <img
            class="img-title01 mb-5 mt-5"
            :src="setting.unidade.diz_vc_logo"
            alt=""
          />
          <p class="text-center mt-4 mb-5 m-p">
            <strong class="b-title"
              >O seu comentário foi enviado com sucesso</strong
            >
            <br />
            <strong class="b-title">e será avaliado pela nossa equipe.</strong>
          </p>
        </div>
      </div>
    </section>

    <MainFooter :background-color="'black'" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainNavbar from "./MainNavbar";
import { Api } from "../../services/api";
import MainFooter from "../MainFooter";
import Qualifiers from "./../Qualifiers";
import moment from "moment";
import { ApiComunicante } from "@/services/apiComunicante";

export default {
  name: "l-page",
  components: { MainFooter, MainNavbar, Qualifiers },
  props: {
    preview: { type: Boolean, required: true },
  },
  data() {
    return {
      atendimento: null,
      cliente: null,
      phone: null,
      grade: null,
      message: null,
      finish: false,
      tempoInicio: "",
      step: "OS",
      tempoFinal: "",
    };
  },
  computed: {
    ...mapState({
      setting: (state) => state.app.configs,
    }),
  },
  mounted() {
    this.tempoInicio = moment().format("YYYY-MM-DD HH:mm:ss");
    const nota = parseInt(this.$route.params.nota);
    if (nota) {
      this.selecionarGrade(nota);
    }
  },
  methods: {
    selecionarGrade(grade) {
      if (grade < 0 || grade > 10) return;

      this.tempoFinal = moment().format("YYYY-MM-DD HH:mm:ss");
      ApiComunicante.enviarEstatistica({
        contaId: this.setting.conta_id,
        dataInicio: this.tempoInicio,
        dataFim: this.tempoFinal,
        uid: this.setting.uid,
        recursoTipoId: 12,
        titulo: this.$route.name == "Qr Code" ? "QR Code" : "Pesquisa",
        descricao: "Layout " + this.setting.layout.toUpperCase(),
        origemChamada: "",
      });

      this.grade = grade;
      const data = this.mountData();

      data.opine =
        this.$route.params.opine && this.$route.params.opine === "opine";

      if (!data.opine) {
        if (this.$route.params.id && this.$route.params.hash) {
          Api.update(this.$route.params.id, this.$route.params.hash, data);
        } else if (this.$route.params.id && this.$route.params.setor) {
          Api.updateQrCode(
            this.$route.params.id,
            this.$route.params.setor,
            data
          );
        }
      }

      this.goToDiv("qualifiers");
    },
    goToDiv(div) {
      setTimeout(() => {
        var elmnt = document.getElementById(div);
        elmnt.scrollIntoView();
      }, 100);
    },
    avancarAposIdentificacao() {
      if (this.$route.name == "Qr Code") {
        this.tempoFinal = moment().format("YYYY-MM-DD HH:mm:ss");
        ApiComunicante.enviarEstatistica({
          contaId: this.setting.conta_id,
          dataInicio: this.tempoInicio,
          dataFim: this.tempoFinal,
          recursoTipoId: 19,
          uid: this.setting.uid,
          titulo: "Qr Code",
          descricao: "Layout " + this.setting.layout.toUpperCase(),
          origemChamada: "",
        });
        this.tempoInicio = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      this.step = "grade";
    },
    async concluir() {
      if (this.preview === false) {
        const data = this.mountData();

        data.opine =
          this.$route.params.opine && this.$route.params.opine === "opine";

        this.finish = true;

        if (this.$route.params.id && this.$route.params.hash) {
          await Api.update(
            this.$route.params.id,
            this.$route.params.hash,
            data
          );
        } else if (this.$route.params.id && this.$route.params.setor) {
          await Api.updateQrCode(
            this.$route.params.id,
            this.$route.params.setor,
            data
          );
          this.timer = setTimeout("window.location.reload()", 30000);
        }
      } else {
        this.finish = true;
      }

      window.scrollTo(0, 0);

      // if(this.preview === false){
      //   const data = {
      //     grade: this.grade,
      //     message: this.message
      //   }

      //   await Api.update(this.$route.params.id, this.$route.params.hash, data)
    },
    mountData() {
      return {
        grade: this.grade,
        message: this.message,
        qualifiers: this.setting.qualifiers,
        uuid: this.setting.uuid,
        cliente: this.cliente,
        atendimento: this.atendimento
          ? this.atendimento
          : this.setting.atendimento,
        phone: this.phone,
      };
    },
    updateMessage(message) {
      this.message = message;
    },
  },
};
</script>

<style scoped>
.b-title {
  font-weight: 600;
  font-size: 1.5em;
}
.body-l {
  width: 100%;
  height: 100%;
  font-family: "Ek Mukta", sans-serif;
  color: #666;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 400;
  overflow-x: hidden;
}

.btn-skin {
  background-color: #039637 !important;
}

.btn-skin-red {
  background-color: #d73336 !important;
}

.btn-skin-orange {
  background-color: #dba801 !important;
}

.l-text-area {
  border: solid 1px #eee !important;
}

.l-text-area:focus {
  border: solid 1px #01a9db !important;
}

.l-divider {
  display: block;
  width: 100%;
  height: 2px;
  margin: 20px auto 15px;
  background: #fff0d8;
}

.l-btn {
  font-size: 14px;
  line-height: 1.35em;
  margin: 10px 1px;
  border: none;
  border-radius: 0.1875rem;
  cursor: pointer;
  background-color: #888888;
  color: #ffffff;

  padding: 6px 10px;
}

a.l-btn:hover {
  text-decoration: none;
}

.l-bg-gray {
  background: #f9f9f9;
}

.l-title-small {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0;
}

.c-input {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #333 !important;
  border: #333;
  border-radius: 5px;
  padding: 0;
  font-size: 1rem;
  text-transform: none;
  font-weight: normal;
  text-align: center;
  letter-spacing: 2px;
}
.c-input::placeholder {
  color: #a8a8a8;
}

.c-input:focus,
.c-input:focus {
  outline: none;
}
.c-input:hover {
  background-color: rgba(255, 255, 255, 0.125) !important;
}
</style>
